import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { parseDateHyphen } from './Tools';

import logo from '../images/logo.png';

const styles = StyleSheet.create({
    subBottom: {
        display: 'flex',
        flexDirection: 'row', // Alterado para 'row'
        paddingTop: '3px',
        borderRadius: '10px',
        height: '40px',
        width: '300px',
        alignItems: 'center',
        paddingLeft: '8px',
    },
    subBottom1: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: '#114681',
        height: '40px',
    },
    textBottom: {
        color: 'white',
        paddingRight: '15px'
    },
    table: {
        width: '95%',
        alignSelf: 'center'
    },
    th: {
        textAlign: 'right',
        marginTop: 15,
        marginBottom: 40,
        fontWeight: 'bold',
        flex: 1, // Adicionado flex
    },
    td: {
        fontSize: 17,
        flex: 1, // Adicionado flex
    },
    tr: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    centralizado: {
        textAlign: 'center',
        fontSize: 17,
        fontWeight: 'extrabold',
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1, // Adicionado flex

        paddingRight: '7px'
    },
    centralizadoTh: {
        textAlign: 'center',
        fontWeight: 'bold',
        flex: 1, // Adicionado flex
        height: 50,
        verticalAlign: "center",
        paddingTop: 10,

    },
    alinhamentoDireita: {
        textAlign: 'right',
        flex: 1, // Adicionado flex
    },
    alinhamentoDireitaTd: {
        textAlign: 'right',
        fontSize: 16,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1, // Adicionado flex

        paddingRight: '7px'
    },
    h1Container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: 10,
        alignContent: 'space-between'
    },
    inside: {
        width: '40%',
        borderBottomWidth: 2,
        height: 40,
        borderColor: '#091f3d',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 10,
        backgroundColor: 'white',
    },
    outside: {
        width: '40%',
        borderBottomWidth: 2,
        height: 40,
        borderColor: '#114681',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 10,
        backgroundColor: 'white',
    },
    h1Text: {
        fontSize: 18,
        fontWeight: "extrabold",
        padding: 5,
        color: 'black',
    },
    span: {
        padding: 15,
    },
    central: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    page: {
        width: '100%'
    },
    blueBlock: {
        justifyContent: 'center',
        alignContent: 'center',
        padding: 10,
        backgroundColor: '#091f3d',
        marginBottom: 20
    },
    greyBlock: {
        width: '100%',
        marginBottom: 20
    },
    greyBlock1: {
        justifyContent: 'center',
        alignContent: 'center',
        padding: 10,
        backgroundColor: '#747373',
        marginBottom: 20
    },
    bottomBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: 20,
        backgroundColor: '#114681',
        margin: '10px',
    },
    trusteeFooter: {
        width: '100%',
    },
    image: {
        width: 100,
        height: 100,
        margin: 10
    },
    subContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignContent: 'space-evenly',
    },
    text: {
        textAlign: 'center',

    },
    textContainer: {
        justifyContent: 'center',
        width: 300,
        height: 60,
        padding: 10,
        margin: 15,
        borderRadius: 5,
        backgroundColor: '#114681',
        color: 'white'
    },
    icon: {
        alignSelf: 'center',
        marginRight: 5,
        width: 20,
        height: 20,
    }
});

function chunkArray(arr, chunkSize) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
}

const MyPDFViewer = ({ onClose }) => {
    const calcData = useSelector((state) => state.calcData.calcData);
    const sacData = useSelector((state) => state.sacData.sacData); // Suponha que a sacData esteja disponível no estado.
    const formValues = JSON.parse(localStorage.getItem('formValues'));

    const data = calcData.length > 0 ? calcData : sacData
    console.log('====================================');
    console.log(data, 'calc data');
    console.log('====================================');
    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const user = JSON.parse(localStorage.getItem('user'));
    
    const now = formValues.dataEmissao ? parseDateHyphen(formValues.dataEmissao):new Date();
    const currentZoom = window.devicePixelRatio;
    console.log(currentZoom);
    const wichBlock = user.data.cnpj === '67030395000146' ? 'greyBlock' : 'blueBlock';

    // Calcula o tamanho do componente PDFViewer com base no zoom
    const pdfViewerWidth = 2500 / currentZoom;
    const pdfViewerHeight = 1200 / currentZoom;

    const chunkedCalcData = chunkArray(data, 7);
    let isFirstPage = true;
    return (
        <PDFViewer width={'90%'} height={'85%'} style={{}}>
            <Document title={`Simulação de Nota Comercial - ${now}`}>
                {chunkedCalcData.map((chunk, pageIndex) => (
                    <Page size="A3" style={styles.page}>
                        <View style={{
                            padding: 10,
                            flex: 1,
                        }}>
                                    <View style={[styles.blueBlock,{ display:"flex" , flexDirection: "row", justifyContent: "flex-start"}]}>
                                    <Image
                                        src={logo}
                                        style={{ width: 100 }}
                                    />
                                    <Image
                                        src="https://i.ibb.co/02hPFrH/verde-branco.png"
                                        style={{ width: 70, marginLeft:10 }}
                                    />
                                </View>
                            {isFirstPage && (
                                <View style={styles.h1Container}>
                                    <View style={styles.inside}>
                                        <Text style={styles.h1Text}>Simulação Realizada por:</Text>
                                    </View>
                                    <View style={styles.outside}>
                                        <Text style={styles.text}> {`${user.data.razaoSocial}`}</Text>
                                    </View>
                                    <View style={styles.inside}>
                                        <Text style={styles.h1Text}>Valor da Operação:</Text>
                                    </View>
                                    <View style={styles.outside}>
                                        <Text style={styles.text}> {`${allInfoArray[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}</Text>
                                    </View>
                                    <View style={styles.inside}>
                                        <Text style={styles.h1Text}>Data de Emissão:</Text>
                                    </View>
                                    <View style={styles.outside}>
                                        <Text style={styles.text}> {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}</Text>
                                    </View>
                                </View>
                            )}

                            {
                                isFirstPage &&
                                <View style={ styles.blueBlock}>
                                    <Text></Text>
                                </View>

                            }

                            {isFirstPage && (
                                <View style={styles.subContainer}>
                                    <View style={styles.textContainer}>
                                        <Text style={styles.text}>Valor Total: {`${allInfoArray[12]}`}</Text>
                                    </View>
                                    <View style={styles.textContainer}>
                                        <Text style={styles.text}>Quantidade de Parcelas: {`${allInfoArray[4]}`}</Text>
                                    </View>
                                    <View style={styles.textContainer}>
                                        <Text style={styles.text}>Taxa de Juros a.m: {`${allInfoArray[10]}%`}</Text>
                                    </View>
                                    <View style={styles.textContainer}>
                                        <Text style={styles.text}>Carência: {`${allInfoArray[19]}`}</Text>
                                    </View>
                                </View>

                            )}
                            <View style={styles.table}>
                                <View style={styles.tr}>
                                    <Text style={styles.centralizadoTh}>Período</Text>
                                    <Text style={styles.centralizadoTh}>Vencimento</Text>
                                    <Text style={styles.centralizadoTh}>Parcela</Text>
                                    <Text style={styles.centralizadoTh}>Amortização</Text>
                                    <Text style={styles.centralizadoTh}>Juros</Text>
                                    <Text style={styles.centralizadoTh}>Saldo Devedor</Text>
                                </View>
                                {/*                                 {isFirstPage && (
                                <View style={styles.tr}>
                                    <Text style={styles.centralizado}>0</Text>
                                    <Text style={styles.centralizado}>
                                        {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}
                                    </Text>
                                    <Text style={styles.alinhamentoDireitaTd}>0</Text>
                                    <Text style={styles.alinhamentoDireitaTd}>0</Text>
                                    <Text style={styles.alinhamentoDireitaTd}>0</Text>
                                    <Text style={styles.alinhamentoDireitaTd}>{allInfoArray[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                </View> )} */}
                                {chunk.map((data, index) => (
                                    <View key={index} style={styles.tr}>
                                        {index === 9 ? <Text style={styles.centralizado}>{data}weweqeqweqwewqewqewq</Text> : null}
                                        <Text style={styles.centralizado}>{data[0]}</Text>
                                        <Text style={styles.centralizado}>
                                            {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                        </Text>
                                        <Text style={styles.alinhamentoDireitaTd}>
                                            {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Text>
                                        <Text style={styles.alinhamentoDireitaTd}>
                                            {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Text>
                                        <Text style={styles.alinhamentoDireitaTd}>
                                            {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Text>
                                        <Text style={styles.alinhamentoDireitaTd}>
                                            {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                        {<View style={styles.bottomBlock}>
                                <View style={styles.subBottom}>
                                    {/* <Image src="https://raw.githubusercontent.com/PRO-Integra/Imagens/main/icon_website.png" style={styles.icon} /><Text style={styles.textBottom}>c2cards.digital</Text>
                                    <Image src="https://raw.githubusercontent.com/PRO-Integra/Imagens/main/icon_instagram.png" style={styles.icon} /><Text style={styles.textBottom}>c2cardsoficial</Text>*/}
                                </View>
                            </View>}
                        {isFirstPage && (pageIndex === 0 ? (isFirstPage = false) : null)}

                    </Page>
                ))}
            </Document>
        </PDFViewer>
    );
};

export default MyPDFViewer;