export function localUser(){
    return JSON.parse(localStorage.getItem('user'));
}

export function userRole(){
    const user = localUser();
    return {
        role: (user.data && user.data.role)? user.data.role : '',
        origemRole: (user.data && user.data.origemRole)? user.data.origemRole : '',
    }
}

export const featureToggle = {
    /** Não pode ser Cliente (Master) e nem Usuário do cliente(Employee)  */
    taxaEmissaoCCB:() => {
        const userRoles = userRole();
        return !((userRoles.role == "Master") || (userRoles.origemRole=="Master" && userRoles.role=="Employee"));
    }
}