import React from 'react';
import { useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Link,
  Image,
  Font,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import capaImage from '../../images/capa_actual_libertas.png';
import page_1a from '../../images/page_01a.png'
import page_02a from '../../images/page_02a.png'
import calibriFont from '../../fontes/calibri.ttf';
import arial_bold from '../../fontes/Arial_Bold.ttf';
import arial from '../../fontes/Arial.ttf';
import { formatDocument, parseDateHyphen } from '../Tools';
import  HyphenationCallback  from "./PdfHyphenation";

Font.registerHyphenationCallback(HyphenationCallback);
Font.register({ family: 'Roboto', src: calibriFont, fontWeight: 'ultrabold'});
Font.register({ family: 'Arial_Bold', src: arial_bold});
Font.register({ family: 'Arial', src: arial});


const styles = StyleSheet.create({
  // ... (seu estilo existente)
  coverPage: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch', // Ajusta o tamanho da imagem para cobrir toda a altura da página
    justifyContent: 'stretch', // Ajusta o tamanho da imagem para cobrir toda a largura da página
  },
  coverTitle: {
 
  },
  coverImage: {
    width: '100%',
    height: '100vh',
  },
  headerImage: {
    width: '25%',
    height:'22',
    paddingTop: '10'
  },
  logoParceiro: {
    width: '10%',
    height:'65',
    paddingTop: '10'
  },
  coverOverlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%', // Usando os 20% restantes para o texto
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adicione um fundo escuro transparente
    boxSizing: 'border-box',
  },
  coverTextContainer: {
    position: 'absolute',
    top: 180,
    left: '20%',
    width: '70%',
    height: '25%', // Usando os 20% restantes para o texto
    boxSizing: 'border-box',
  },
  coverTextContainerPageTwoAndNext: {
    position: 'absolute',
    top: 45,
    left: '3%',
    width: '90%',
    boxSizing: 'border-box',
  },
  coverTextContainer_3: {
    position: 'absolute',
    top: 15,
    left: '3%',
    width: '90%',
    boxSizing: 'border-box',
  },
  coverTextContainer_4: {
    position: 'absolute',
    bottom: 45,
    left: '10%',
    width: '75%',
    boxSizing: 'border-box',
    textAlign:'center'
  },
  coverTextContainer_5: {
    position: 'absolute',
    bottom: 80,
    left: '5%',
    width: '75%',
    boxSizing: 'border-box',
    textAlign:'center'
  },
  coverTextContainer_6: {
    position: 'absolute',
    top: 50,
    right: 0,
    width: '75%',
    boxSizing: 'border-box',
    textAlign:'center'
  },
  coverTextContainer_1: {
    position: 'absolute',
    top: 120,
    left: '10%',
    width: '70%',
    height: '25%', // Usando os 20% restantes para o texto
    boxSizing: 'border-box',
   
  },
  coverText: {
    color: 'black',
    fontSize: 18,
    textTransform: 'uppercase',
    marginLeft:60,
    marginTop:25,
    paddingTop: 10,
    fontFamily: 'Arial_Bold',
    textAlign:'center',
    width: 335, 
  },
  coverSubtext: {
    color: 'black',
    fontSize: 10,
    marginLeft:60,
    marginBottom:20,
    paddingTop: 13,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
    textAlign:'center',
    width: 335, 
  },
  coverDate: {
    color: 'black',
    fontSize: 10,
    paddingTop: 20,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
    textAlign:'right',
  },
  insideTextSalutation: {
    color: 'black',
    fontSize: 10,
    marginLeft:80,
    paddingTop: 7,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
  },
  
  insideTextSignin: {
    color: 'black',
    fontSize: 10,
    paddingTop: 7,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
  },
  insideTextIntro: {
    color: 'black',
    fontSize: 10,
    marginTop: 13,
    marginLeft: 85,
    paddingLeft:15,
    maxWidth: 450,
    marginBottom:30,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
    borderLeft:2,
    borderColor: "#fcaa25"
  },
  coverText_texto: {
    color: 'black',
    fontSize: 10,
    paddingTop: 12,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
  },
  subTitle:{
    color: 'black',
    fontSize: 10,
    paddingTop: 12,
    fontFamily: 'Arial_Bold',
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
  },
  boldText:{
    fontFamily: 'Arial_Bold',
  },
  coverTextBankData: {
    color: 'black',
    fontSize: 10,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
  },
  coverText_texto_2:{
    color: 'black',
    fontSize: 9,
    textTransform: 'uppercase',
    paddingTop: 5,
    width:'100%',
    left: 10,
  },
  coverText_texto_3:{
    color: '#004f9f',
    fontSize: 9,
    textTransform: 'uppercase',
    paddingTop: 5,
    width:'100%',
  },
  coverText_texto_4:{
    color: 'white',
    fontSize: 9,
    paddingTop: 5,
    width:'100%',
  },
  coverText_titulo_1: {
    color: 'black',
    fontSize: 18,
    textTransform: 'uppercase',
    paddingTop: 10,
    fontFamily: 'Arial_Bold',
  },
  coverText_titulo_2: {
    color: 'black',
    fontSize: 13,
    textTransform: 'uppercase',
    paddingTop: 20,
    fontFamily: 'Arial_Bold',
  },
  coverText_titulo_3: {
    color: 'black',
    fontSize: 11,
    textTransform: 'uppercase',
    paddingTop: 15,
    fontFamily: 'Arial_Bold',
    borderBottom:2,
    borderColor: "#fcaa25"
  },
  titlePage:{
    textAlign: 'center',
  },
  titlePage_2:{
    fontSize: 25,
    textAlign: 'center',
    marginBottom: 30,
    color: '#646464',
  },
  coverText4: {
    color: '#cccccc',
    fontSize: 17,
    paddingTop: 20,
  },
  coverText2: {
    paddingTop: 10,
    left:20,
    width:'90%',
    right:20,
    borderBottomWidth: 2,
    borderBottomColor: '#68472c', // Adicione esta linha para a cor da borda
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 20,
    width:'100%'
  },

  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  
  /*tabela*/
  carac_emissao:{
    paddingLeft:40,
    paddingRight:60,
    marginTop: '80px',
    marginBottom: '80px',
  },
  carac_emissao_item:{
    borderColor: '#c6c6c6', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderLeft: 2, // Borda no lado esquerdo
    borderTop: 2,   // Borda na parte superior
    width:'30%',
    padding: 5,
  },
  carac_emissao_item_last:{
    borderColor: 'black', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderLeft: 2, // Borda no lado esquerdo
    borderBottom: 2,   // Borda na parte superior
    borderTop: 2,
    width:'30%',
    padding: 5
  },
  carac_emissao_item_last_2:{
    borderColor: 'black', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderBottom: 2,   // Borda na parte superior
    borderTop: 2,
    width:'70%',
    padding: 5
  },
  carac_emissao_item_2:{
    borderColor: '#4f4f4f', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderTop: 2,   // Borda na parte superior
    width:'70%',
    padding: 5
  },
  carac_emissao_line:{
    flexDirection: 'row', // Coloca os elementos lado a lado
    justifyContent: 'space-between', // Distribui espaço entre os elementos
  },
  carac_emissao_line_2:{
    flexDirection: 'row', // Coloca os elementos lado a lado
    justifyContent: 'space-between', // Distribui espaço entre os elementos
    backgroundColor:'#cccccc',
  },
  footer:{
    height:'50px',
    backgroundColor:'#262626',
    textAlign: 'center',
    color: 'white',
    padding: 10,
    fontSize: 14,
    bottom:3,
    position:'absolute',
    width:'100%',
  },
  data_proposta:{
    fontSize: 13,
    marginLeft: 'auto',
    paddingRight: 10,
  },
  contatos_proposta:{
    fontSize: 13,
    marginLeft: 'auto',
    paddingRight: 10,
    marginTop:50,
  },
  container: {
    padding: 10,
    marginLeft:30,
    marginRight: 30,
    color:'#636363'
  },
  listItem: {
    marginBottom: 10,
  },
  borderBottom_tile: {
    borderBottom: 1,
    borderColor: '#646464',
    width: '100%',
    marginBottom: 10,
  },

  subtitulo:{
    fontWeight: 'bold', // Adicionando negrito
    marginBottom: 10,
    borderBottom: 2,
    width:'42%',
    color:'#464646',
    borderColor: '#464646', // Cor da borda
  },
  subtitulo_2:{
    color: '#323232', // Cor da borda
  },
  subContainer: {
    position:'absolute',
    flexDirection: 'row',
    top:50,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'space-evenly',
  },
  text: {
    textAlign: 'center',
  },
  textSigninBlock: {
    marginTop: 10,
    marginBottom: 10,
    paddingBottom: 25,
    borderTop: 1,
    borderBottom: 1,
  },
  textContainer: {
    justifyContent: 'center',
    width: 230,
    height: 60,
    fontSize: 15,
    padding: 10,
    margin: 15,
    borderRadius: 5,
    backgroundColor: '#00336a',
    color: 'white'
 },
 paymentFlowAmountContainer: {
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  alignContent: 'space-evenly',
},
 table: {
    display:"flex",
    width: '95%',
    alignSelf: 'center',
    fontSize: 12,
 },
 tr: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    backgroundColor:'white'
 },
 centralizadoTh2: {
    textAlign: 'center',
    fontWeight: 'bold',
    flex: 1, // Adicionado flex
    verticalAlign: "center",
    paddingTop: 10,
 },
 centralizadoTh: {
    textAlign: 'center',
    fontWeight: 'bold',
    flex: 2, // Adicionado flex
    verticalAlign: "center",
    paddingTop: 10,
 },
 centralizado2: {
    textAlign: 'center',
    fontWeight: 'extrabold',
    paddingTop: 20,
    paddingBottom: 20,
    flex: 1, // Adicionado flex
    paddingRight: '7px'
},
 centralizado: {
    textAlign: 'center',
    fontWeight: 'extrabold',
    paddingTop: 20,
    paddingBottom: 20,
    flex: 2, // Adicionado flex
    paddingRight: '7px'
},
alinhamentoDireitaTd: {
    textAlign: 'right',
    paddingTop: 20,
    paddingBottom: 20,
    flex: 2, // Adicionado flex
    paddingRight: '7px'
},
footer_table:{
  marginVertical: 5, // Ajuste essa propriedade para o espaçamento desejado
},
 rodape: {
    marginTop: 10, // Ajuste conforme necessário
    borderTopWidth: 1, // Adiciona uma borda superior ao rodapé
    paddingTop: 5, // Ajuste conforme necessário
    alignItems: 'center',
  },
  textoRodape: {
    fontSize: 12,
    color: 'gray', // Ajuste a cor conforme necessário
  },

   
});
const MyPDFViewer = ({ onClose ,dadosProposta }) => {
  //const coverImage = capaImage;
    //const logo = logo;
    const storedData = JSON.parse(localStorage.getItem('formValues'));
    const dataAtual = storedData.dataEmissao ? parseDateHyphen(storedData.dataEmissao):new Date();
    const allInfoArray1 = JSON.parse(localStorage.getItem('allInfoArray'));
    const allInfoArray2 = useSelector((state) => state.finalTotal.finalTotal)
    const allInfoArray = allInfoArray1 || allInfoArray2
    const anoAtual = dataAtual.getFullYear();
    const [emissaoPorcento, setEmissaoPorcento] = useState(storedData ? storedData.emissaoPorcento : '');
  //  const [parcelas, setParcelas] = useState(storedData ? storedData.parcelas : '');
  //  const [jurosAA, setJurosAA] = useState(storedData ? storedData.jurosAA : '');
    const paisagemWidth = 15; // polegadas
    const paisagemHeight = 8.5; // polegadas
   const fonts = { Roboto: { normal: calibriFont } };
    const getCurrentDate = () => {
        return dataAtual.toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
    };
    function chunkArray(arr, chunkSize) {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    }
    const calcData = useSelector((state) => state.calcData.calcData);
    const sacData = useSelector((state) => state.sacData.sacData); // Suponha que a sacData esteja disponível no estado.
    const data = calcData.length > 0 ? calcData : sacData
    const chunkedCalcData = chunkArray(data, 5);
    let isFirstPage = true;
   // const data = calcData.length > 0 ? calcData : sacData
   // const [jurosAA, setJurosAA] = useState('');
  console.log(chunkedCalcData)
return (
  
    <PDFViewer width={'90%'} height={'85%'} >
      <Document title={`Nota Comercial - ${dadosProposta.NumeroProposta}`}>
        {/* Capa */}
        <Page size="A4"  orientation="portrait" style={styles.page}>
            <View style={styles.coverPage}>
                <Image src={capaImage} style={styles.coverImage} />
            </View>
        </Page>

           {/* Pag 1 */}
           <Page  size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.coverPage}>
                  <Image src={page_1a} style={styles.coverImage} /> 
                </View>
                <View style={styles.coverTextContainer_3}>
                    <Text style={styles.coverText}>
                        PROPOSTA
                    </Text >
                    <Text style={styles.coverSubtext}>
                        PRESTAÇÃO DE SERVIÇOS PARA EMISSÃO DE NOTA COMERCIAL
                    </Text >
                    <Text style={styles.coverDate}>
                      São Paulo, {getCurrentDate()}
                    </Text >
                    
                    <Text style={[styles.insideTextSalutation, {marginTop:30}]}>
                      A {dadosProposta.Solicitante}
                    </Text >
                    <Text style={styles.insideTextSalutation}>
                      At.  Sr.(a) {dadosProposta.Responsavel}
                    </Text >

                    <Text style={
                          [styles.insideTextIntro, {marginTop:50}]
                        }>
                        Visando oferecer um atendimento de excelência, apresentamos nossa proposta comercial para prestação  de serviços de formalização de Notas Comerciais.
                    </Text >

                  <Text style={[styles.coverText_titulo_3,{ width:"100%"}]}>
                    FLUXO DE PAGAMENTO PROPOSTO
                  </Text >
                  <View style={styles.paymentFlowAmountContainer}>
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>Valor Total: {`${allInfoArray[12]}`}</Text>
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>Quantidade de Parcelas: {`${allInfoArray[4]}`}</Text>
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>Taxa de Juros a.m: {`${allInfoArray[10]}%`}</Text>
                    </View>
                    <View style={styles.textContainer}>
                      <Text style={styles.text}>Carência: {`${allInfoArray[19]}`}</Text>
                    </View>
                  </View>

                  <View style={styles.table}>
                    <View style={styles.tr}>
                      <Text style={styles.centralizadoTh2}>Período</Text>
                      <Text style={styles.centralizadoTh}>Vencimento</Text>
                      <Text style={styles.centralizadoTh}>Parcela</Text>
                      <Text style={styles.centralizadoTh}>Amortização</Text>
                      <Text style={styles.centralizadoTh}>Juros</Text>
                      <Text style={styles.centralizadoTh}>Saldo Devedor</Text>
                    </View>
                    
                    {chunkedCalcData.map((chunk, pageIndex) => (
                            <View key={pageIndex}> 
                                {chunk.map((data, index) => (
                                    <View key={index} style={styles.tr}>
                                      <Text style={styles.centralizado2}>
                                        {data[0]}
                                      </Text>
                                      <Text style={styles.centralizado}>
                                      {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} 
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                  </View>
                                ))}
                            </View>
                          ))}             
                        </View>

                    {/*
                    <Text style={styles.coverText_texto}>{`${emissaoPorcento}`} %  sobre o valor da emissão da nota comercial;</Text >
                    */}
                    <Text style={[styles.coverText_titulo_3,{marginTop: 60}]}>
                      1) ACESSO A PLATAFORMA - NOTA COMERCIAL
                    </Text >
                     
                    <Text style={styles.coverText_texto}>
                      1.1 Mediante concordância e assinatura dos termos de emissão, será disponibilizado acesso individual e exclusivo, por meio de login e senha, à plataforma actual.c2ss.com.br. Este acesso permite a realização de simulações de operações, construção de cenários para auxílio na tomada de decisões de crédito e elaboração de forma autônoma de minutas para escrituração de Notas Comerciais. 
                    </Text >
                    <Text style={styles.coverText_texto}>
                      1.2 O acesso à plataforma não possui custo de aquisição ou cobrança pelo licenciamento e/ ou manutenção dos mesmos. O contratante declara desde já consentimento quanto ao fato desta condição não ser permanente e que, em função de revisões nas políticas comerciais, a Actual, Libertas e a Cred2Cards poderão a qualquer momento, sem aviso prévio, estabelecer regras de cobrança pelo uso de seus sistemas.
                    </Text >
                    <Text style={styles.coverText_titulo_3}>
                      2) MINUTAS DE NOTA COMERCIAL
                    </Text >
                    <Text style={styles.coverText_texto}>
                      2.1 Será disponibilizado, através do acesso a plataforma actual.c2ss.com.br, Termos Constitutivos de Nota Comercial, Boletim de Subscrição e Contrato de Condições Negociais previamente aprovadas pelas escrituradoras conveniadas para realização de operações <Text style={styles.boldText}> COM </Text> e <Text style={styles.boldText}> SEM </Text> garantia real. Havendo necessidade de alteração em qualquer cláusula ou conteúdo de alguma minuta, esta solicitação será tratada como uma personalização e deverá ser previamente submetida ao departamento jurídico da escrituradora para aprovação. 
                    </Text >
                    <Text style={styles.coverText_texto}>
                      2.2 Havendo a personalização de minuta o tempo para conclusão da escrituração será de até cinco dias úteis a contar da data de solicitação, pelo emissor, da minuta personalizada.
                    </Text >
                </View>
          </Page>
          {/* Pag 2 */}
          <Page  size="A4" orientation="portrait" style={styles.page}>
              <View style={styles.coverPage}>
                <Image src={page_02a} style={styles.coverImage} />
              </View>
              <View style={
                  styles.coverTextContainerPageTwoAndNext
                  }>
                <Text style={styles.coverText_titulo_3}>
                  3) PRAZO PARA DISPONIBILIZAÇÃO DE MINUTAS
                </Text >
                <Text style={styles.coverText_texto}>
                  3.1 Operações <Text style={styles.boldText}>COM</Text> cadastro de Investidor e Emissor previamente aprovados, o prazo de disponibilização para coleta de assinaturas digitais e liberação para liquidação da Nota Comercial, será de <Text style={styles.boldText}>UM</Text> dia útil.
                </Text >
                <Text style={styles.coverText_texto}>
                  3.2 Operações <Text style={styles.boldText}>SEM</Text> cadastro de Investidor e Emissor previamente aprovados, o prazo de disponibilização para coleta de assinaturas digitais e liberação para liquidação da Nota Comercial será de <Text style={styles.boldText}>CINCO</Text> dias úteis.
                </Text >
                <Text style={styles.coverText_texto}>
                  3.3 Operações <Text style={styles.boldText}>COM</Text> garantia real <Text style={styles.boldText}>IMÓVEL</Text>: o investidor deverá estabelecer a regra de liberação do crédito se: No ato da obtenção do protocolo para alienação fiduciária obtida junto ao cartório de imóveis da comarca competente ou se, somente, após a liberação pelo cartório da matrícula devidamente <Text style={styles.boldText}>ALIENADA</Text> ao investidor da Nota Comercial. Caso o investidor seja um FIDC – Fundo de Investimentos em Direito Creditório poderá ser exigido pela administradora um laudo de avaliação do bem imóvel emitido por profissional habilitado, e este deverá ser providenciado pelo investidor.
                </Text >
                <Text style={styles.coverText_texto}>
                  3.4 Operações <Text style={styles.boldText}>COM</Text> garantia real <Text style={styles.boldText}>VEÍCULO</Text>: o investidor deverá providenciar o registro da alienação fiduciária no documento CRVL – Certificado de Registro de Financiamento de Veículos, de forma que o proprietário seja impedido de comercializar, ceder, doar ou promover nova alienação ao bem cedido em garantia da operação. Para realização da alienação é necessário que o investidor tenha previamente recebido acesso ao <Text style={styles.boldText}>SNG</Text> – Sistema Nacional de Gravames que é gerido pela B3 – Brasil, Bolsa e Balcão e posterior registro do Termo de Constituição da Nota Comercial no <Text style={styles.boldText}>DETRAN</Text> devidamente responsável pelo licenciamento do veículo. 
                </Text >
                <Text style={styles.coverText_texto}>
                  3.5 	Operações <Text style={styles.boldText}>COM</Text> garantia real RECEBÍVEIS ORIUNDOS DE VENDAS COM CARTÃO DE CRÉDITO: o registro da operação de cessão de recebíveis, troca de titularidade, uso da agenda de recebíveis como garantia, alteração do domicílio bancário, deverá ser realizada pelo investidor.
                </Text >
                <Text style={styles.coverText_texto}>
                  3.6 	Operações <Text style={styles.boldText}>COM</Text> garantia real <Text style={styles.boldText}>MAQUINÁRIOS</Text>: é de total responsabilidade do investidor: admitir tais equipamentos como garantia das operações, avaliar as condições de funcionamento, valor de mercado e depreciação do bem. 
                </Text >
                <Text style={styles.coverText_texto}>
                  3.7	A Cred2Cards não participa, interfere ou sugere nenhum tipo de avaliação sob qualquer bem móvel ou imóvel, admitido como garantia real das operações de nota comercial e, portanto, desde já o investidor a isenta de qualquer responsabilidade pela atribuição de valor ao bem, bem como, responsabilidade por seu pleno funcionamento ou participação em futura ação de busca apreensão ou retomada do bem.
                </Text >
                <Text style={styles.coverText_texto}>
                  3.8	A decisão quanto a concessão de crédito tomada pelo investidor, é de sua única e exclusiva responsabilidade, em função da própria análise dos riscos e benefícios envolvidos na emissão. Assim, o investidor se compromete a manter a Cred2Cards, seus administradores, diretores, empregados e/ou prepostos indenes com relação a toda e qualquer responsabilidade por perdas, danos, despesas e demandas judiciais de terceiros, surgidas a partir da data de assinatura deste instrumento.
                </Text >
                <Text style={styles.coverText_texto}>
                  3.9 	O investidor reconhece que o papel da Actual, Libertas e da Cred2Cards é meramente tecnológico atuando na disponibilização de minutas de Termo Constitutivo e Boletim de Subscrição previamente aprovadas pela DTVM responsável pela escrituração da Nota Comercial. O investidor declara ainda que reconhece sua responsabilidade quanto a prévia aprovação do conteúdo das clausulas das minutas junto a seu departamento jurídico e comitê interno de decisão de crédito, sendo estes únicos responsáveis pela aprovação do conteúdo do Termo Constitutivo, Boletim de Subscrição e Minuta de Alienação Fiduciária quando aplicável.
                </Text >
              </View>
            </Page>
          {/* Pag 3 */}
          <Page  size="A4" orientation="portrait" style={styles.page}>
              <View style={styles.coverPage}>
                <Image src={page_02a} style={styles.coverImage} />
              </View>
              <View style={
                  styles.coverTextContainerPageTwoAndNext
                  }>
                <Text style={styles.coverText_titulo_3}>
                  4) ADITAMENTO
                </Text >
                <Text style={styles.coverText_texto}>
                  4.1 Após o processo de assinatura digital do Termo de Constituição e/ou Escrituração se necessário alguma alteração em minutas, como: Inclusão de garantias adicionais, mudança de dados cadastrais de emissores, investidores ou avalistas entre outras, será realizado um processo de aditamento para correção. Em função da necessidade de elaboração do termo aditivo este processo possui o mesmo custo da taxa de emissão. 
                </Text >
                <Text style={styles.coverText_titulo_3}>
                  5) PREÇO
                </Text >
                <Text style={styles.coverText_texto}>
                  5.1 Pela prestação do serviço de formalização e escrituração de nota comercial será cobrado o valor de {Number(storedData.taxaEmissao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} do valor total da Nota Comercial.
                </Text >
                <Text style={styles.coverText_texto}>
                  5.2 A escrituração é realizada por uma DTVM – Distribuidora de Títulos e Valores Mobiliários devidamente autorizada pela CVM – Comissão de Valores Mobiliários, que presta serviço à Cred2Cards, sendo esta responsável pelo pagamento dos honorários.
                </Text >
                <Text style={styles.coverText_texto}>
                  5.3 O pagamento total pela prestação de serviços de formalização e escrituração da nota comercial, deverá ser realizado no ato da primeira integralização da nota comercial, através de transferência eletrônica em conta corrente conforme dados abaixo:
                </Text >
                <Text style={[styles.coverTextBankData,{marginTop:5}]}>
                  Razão Social: Cred2cards Serviços de Tecnologia Ltda
                </Text >
                <Text style={styles.coverTextBankData}>
                  Banco Inter: 077
                </Text >
                <Text style={styles.coverTextBankData}>
                  Agência: 0001
                </Text >
                <Text style={styles.coverTextBankData}>
                  Conta Corrente: 232.033.46-3
                </Text >
                <Text style={styles.coverTextBankData}>
                  Chave PIX: CNPJ 47.440.351/001-87
                </Text >
                <Text style={styles.coverText_titulo_3}>
                  6) CANCELAMENTO
                </Text >
                <Text style={styles.coverText_texto}>
                  6.1 Uma vez assinada a proposta comercial, caso haja desistência no processo de emissão da Nota Comercial o investidor não será isento da cobrança da taxa de emissão, sendo devido à Actual, Libertas e Cred2Cards o valor referente a taxa de serviço de formalização de nota comercial, estabelecido na clausula 5.1 desta proposta comercial.
                </Text >
                <Text style={styles.coverText_titulo_3}>
                  7) CONDIÇÕES GERAIS
                </Text >
                <Text style={styles.coverText_texto}>
                  6.1 Uma vez assinada a proposta comercial, caso haja desistência no processo de emissão da Nota Comercial o investidor não será isento da cobrança da taxa de emissão, sendo devido à Actual, Libertas e Cred2Cards o valor referente a taxa de serviço de formalização de nota comercial, estabelecido na clausula 5.1 desta proposta comercial.
                </Text >
                <Text style={styles.subTitle}>
                  7.1 CONFIDENCIALIDADE 
                </Text>
                <Text style={styles.coverText_texto}>
                  7.1.1 Os termos desta proposta e as informações dela resultantes (“Informações”) são confidenciais, não devendo ser publicados ou divulgados a terceiros, por qualquer meio, sem o prévio consentimento por escrito das partes.
                </Text>
              </View>
          </Page>

           {/* Pag 4 */}
          <Page  size="A4" orientation="portrait" style={styles.page}>
              <View style={styles.coverPage}>
                <Image src={page_02a} style={styles.coverImage} />
              </View>
              <View style={
                  styles.coverTextContainerPageTwoAndNext
                  }>
                <Text style={styles.coverText_texto}>
                  7.1.2 A obrigação de confidencialidade das Informações não será aplicável nos seguintes casos: (i) determinação judicial, legal, administrativa ou normativa exigindo seu fornecimento; (ii) quando divulgadas de acordo com os procedimentos necessários à realização da emissão; (iii) quando tais Informações venham a ser disponíveis para o público em geral por outras fontes que não a parte detentora original das Informações ou o cliente; ou (iv) quando tais Informações venham a se tornar disponíveis à outra parte de forma não confidencial por terceiros (não relacionados ao cliente e/ou à presente proposta) autorizados a fornecê-las.
                </Text>
                <Text style={styles.coverText_texto}>
                  7.1.3 Os representantes das partes, inclusive sociedades de seus respectivos grupos econômicos envolvidos na emissão, analistas e demais pessoas envolvidas na estruturação da emissão não serão consideradas terceiros para fins da cláusula acima, devendo, entretanto, cumprir as obrigações ali previstas.
                </Text>
                <Text style={styles.coverText_texto}>
                  7.2 VALIDADE
                </Text>
                <Text style={styles.coverText_texto}>
                  7.2.1 A presente proposta é válida pelo prazo de 10 (dez) dias corridos, após o qual, não havendo o “De Acordo” do cliente, todas as cláusulas e condições ora apresentadas serão consideradas nulas de pleno direito  ou poderão ser revistas pela Actual, Libertas e Cred2cards, a seu exclusivo critério e sem aviso prévio.
                </Text>
                <Text style={styles.coverText_texto}>
                  7.3 ASSINATURA
                </Text>
                <Text style={styles.coverText_texto}>
                  7.3.1	Caso o cliente esteja de acordo com os termos da presente proposta, solicitamos-lhe a aposição da assinatura de seus representantes no campo apropriado, localizado ao final desta proposta.
                </Text>
                <Text style={styles.coverText_texto}>
                  7.3.2 	Para os fins do artigo 10, parágrafo 2o, da Medida Provisória no 2.200-2, de 24 de agosto de 2001, as partes acordam e aceitam que este instrumento e qualquer aditamento podem ser assinados eletronicamente, com certificados digitais emitidos pela ICP-Brasil, e tais assinaturas eletrônicas serão legítimas e suficientes para comprovar (i) a identidade de cada representante legal, (ii) a vontade de cada parte em firmar este instrumento e qualquer aditamento, e (iii) a integridade deste instrumento e qualquer alteração.
                </Text >
                <Text style={styles.coverText_titulo_3}>
                  8) LGPD
                </Text >
                <Text style={styles.coverText_texto}>
                  8.1. Conforme prevê a Lei Geral de Proteção de Dados, todos os dados serão tratados respeitando os princípios da finalidade, adequação, transparência, livre acesso, segurança, prevenção e não discriminação. (Art. 6o, LGPD)
                </Text>
                <Text style={styles.coverText_texto}>
                  São Paulo, {getCurrentDate()}
                </Text>
                <Text style={styles.coverText_texto}>
                  De acordo,
                </Text>
                <View style={styles.textSigninBlock}>
                  <Text style={styles.insideTextSignin}>
                    CRED2CARDS SERVIÇOS DE TECNOLOGIA LTDA
                  </Text>
                  <Text style={styles.insideTextSignin}>
                    CNPJ: 47.440.351/0001-87
                  </Text>
                  <Text style={styles.insideTextSignin}>
                    César de França Galvão Neto
                  </Text>
                  <Text style={styles.insideTextSignin}>
                    CPF: 298.608.778-74
                  </Text>
                </View>
                <View>
                  <Text style={styles.insideTextSignin}>
                  Investidor: {dadosProposta.Solicitante}
                  </Text>
                  <Text style={styles.insideTextSignin}>
                  CNPJ: {formatDocument(dadosProposta.Cnpj)}
                  </Text>
                  <Text style={styles.insideTextSignin}>
                  Responsável: {dadosProposta.Responsavel}
                  </Text>
                  <Text style={styles.insideTextSignin}>
                    CPF: {formatDocument(dadosProposta.Cpf)}
                  </Text>
                </View>
            </View>
          </Page> 
           {/* Pag 6 */}
           {/*
          <Page  size="A4" orientation="portrait" style={styles.page}>
                    <View style={styles.coverPage}>
                        <Image src={page_02a} style={styles.coverImage} />
                    </View>
                    <View style={styles.subContainer}>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Valor Total: {`${allInfoArray[12]}`}</Text>
                        </View>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Quantidade de Parcelas: {`${allInfoArray[4]}`}</Text>
                        </View>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Taxa de Juros a.m: {`${allInfoArray[10]}%`}</Text>
                        </View>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Carência: {`${allInfoArray[19]}`}</Text>
                        </View>
                        <View style={styles.table}>
                          <View style={styles.tr}>
                            <Text style={styles.centralizadoTh}>Período</Text>
                            <Text style={styles.centralizadoTh}>Vencimento</Text>
                            <Text style={styles.centralizadoTh}>Parcela</Text>
                            <Text style={styles.centralizadoTh}>Amortização</Text>
                            <Text style={styles.centralizadoTh}>Juros</Text>
                            <Text style={styles.centralizadoTh}>Saldo Devedor</Text>
                          </View>
                        {chunkedCalcData.map((chunk, pageIndex) => (
                        
                            <View key={pageIndex}> 
                                {chunk.map((data, index) => (
                                  
                                    <View key={index} style={styles.tr}>
                                      {index === 9 ? <Text style={styles.centralizado}>{data}</Text> : null}
                                      <Text style={styles.centralizado}>{data[0]}</Text>
                                      <Text style={styles.centralizado}>
                                      {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} 
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                  </View>
                                ))}
                            </View>
                          ))}             
                        </View>
                    </View>
          </Page>
        */}
            {/* Final */}
      </Document>
    </PDFViewer>
  );
};

export default MyPDFViewer;
