import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../images/logo_pdf.png';
import { parseDateHyphen, estadoPorExtenso } from "../Tools";
const generatePDF = (dados, onBlobReady) => {
  const margin = 11; // margem em milímetros
  const pdf = new jsPDF({
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    marginLeft: margin,
    marginRight: margin,
  });
  const formValues = JSON.parse(localStorage.getItem('formValues'));
  const now = formValues.dataEmissao ? parseDateHyphen(formValues.dataEmissao):new Date();
  const emissor = JSON.parse(localStorage.getItem('emissor')); //avalistas
  const investidor = JSON.parse(localStorage.getItem('investidor')); //investidor
  const garantia = JSON.parse(localStorage.getItem('garantiaDataArray'));
  //const garantias = localStorage.getItem('garantiaDataArray'); //investidor
  console.log(garantia,'garantias')
  const allInfoArray =  dados.allInfoArray
  let calcData = dados.calcData
  const valorTotal = allInfoArray[13]
  const linhaDoVencimento = calcData[calcData.length - 1]
  const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
  const dataHoje = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
  const numero = require('numero-por-extenso');
  const base = formValues.dias === 30 ? 360 : 252;
  
  // Largura do contêiner disponível com base nas margens
 const containerWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
  let yPosition = 4; // Posição inicial do eixo y

 
  const imageUrl = logo;
  const leftMargin = 10; // Margem esquerda
  const imageWidth = 42;
  const imageHeight = 12;


  pdf.addImage(imageUrl, 'PNG', leftMargin, yPosition, imageWidth, imageHeight);
//Titulo
  yPosition += 2; // Adicione algum espaçamento abaixo do título
  const fontSizePadrao = 13;
  pdf.setFontSize(fontSizePadrao); // Tamanho da fonte
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte
  const text = `TERMO CONSTITUTIVO DE NOTA COMERCIAL DA 1ª (PRIMEIRA) EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA COLOCAÇÃO PRIVADA, ${emissor.razaoSocial}`
  pdf.text(text, margin, yPosition + imageHeight + 12, { maxWidth: containerWidth });

  yPosition += 18; // Adicione algum espaçamento abaixo do título
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
   const text1 = 'I. EMISSORA';
  pdf.text(text1, margin, yPosition + imageHeight + 20, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte
 //tabela 1
  const columns1 = ['Razão Social', 'CNPJ/MF'];
  const rows = [
    [`${emissor.razaoSocial}` ,`${emissor.cnpj}`],
    // Adicione mais linhas conforme necessário
  ];
  
 //Tabela 1
  pdf.autoTable({
    head: [columns1],
    body: rows,
    startY:  yPosition += 37, // Posição inicial do eixo y
    theme: 'grid',
    headStyles: {
      fillColor: [255, 255, 255], // Cor de fundo da coluna
      textColor: [0, 0, 0]       // Cor do texto da coluna
    },
    styles: {
      fontSize: 11, // Tamanho da fonte da tabela
      textColor: '#00000',
    },
    
    
  });
//Texto 2
   yPosition = 55
  const fontSizetext2 = 11.4;
  pdf.setFontSize(fontSizetext2);
  const text2 =`A Emissão e a celebração deste Termo Constitutivo de Nota Comercial da ${dados.dados.emissao} Emissão de Notas Comerciais, em Série Única, para Colocação Privada, da ${emissor.razaoSocial} (“Termo Constitutivo”) são realizadas com base nas deliberações dos administradores da Emissora, conforme previsto no Parágrafo único, artigo 46 da Lei nº14.195, de 26 de agosto de 2021 (“Lei 14.195”) e no contrato social da Emissora.`
  pdf.text(text2, margin, yPosition + imageHeight + 20, { maxWidth: containerWidth });

  yPosition += 28; // Adicione algum espaçamento abaixo do título
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
   const titulo2 = 'II. RESUMO DA EMISSÃO';
  pdf.text(titulo2, margin, yPosition + imageHeight + 20, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte
//Tabela 2
  const columns2 = ['', '','',''];
  
  const data = [
    [`Data de Vencimento\n${dataDoVencimento}`,`Valor Total da Emissão\n${valorTotal}`,`Valor Nominal Unitário da Emissão\n${valorTotal}`,`Quantidade\n1`],
    [`Juros Remuneratórios \n ${allInfoArray[10]} % a.m. , base ${base} (${numero.porExtenso(base, numero.estilo.normal)}) dias`],
    [`Local de Pagamento \n São Paulo – SP, em conta\ncorrente do Titular`,`Atualização Monetária\nAs Notas Comerciais não\nserão atualizadas monetariamente`,`Juros Remuneratórios\n${allInfoArray[10]}% a.m. base ${base} (${numero.porExtenso(base, numero.estilo.normal)}) dias.`, `Datas de Pagamento dos\nJuros Remuneratórios\nConforme Fluxo de\npagamento do Anexo 2`],
    ['Data de Pagamento do Valor Nominal\nUnitário\nNa data de vencimento.','Resgate Antecipado Facultativo Total\ne Oferta de Resgate\n Antecipado\nMediante o pagamento dos juros incorridos\n no respectivo periodo.','Amortização Extraordinária\nHaverá possibilidade\nde amortização \nextraordinária, seja total ou parcial, das Notas Comerciais,\n desde que amortizados também\n os juros incorridos no respectivo período','Resgate Antecipado\n Compulsório Total\nAs Notas Comerciais deverão ser resgatadas integralmente\ncaso seja decretado o Vencimento Antecipado das Notas\nComerciais, conforme\n abaixo definido'],
  ];
  
  pdf.autoTable({
   // head: [columns2],
    body: data,
    startY:  yPosition += 40, // Posição inicial do eixo y
    theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
    styles: {
      head: {
        fillColor: [255, 255, 255], // Cor branca para o cabeçalho
        textColor: [0, 0, 0], // Cor do texto no cabeçalho
        halign: 'center', // Centraliza o texto horizontalmente
        valign: 'middle', // Centraliza o texto verticalmente
      },
      fontSize: 11, // Tamanho da fonte da tabela
      body: {
        textColor: [0, 0, 0], // Cor do texto no corpo da tabela
      },
      table: {
        lineWidth: 0.5, // Espessura da linha da tabela
      },
      textColor: '#00000',
    }
  });
  //titulo 3
  yPosition += 105

   /***********************************Página 2*********************************************************************** */
  // Adiciona uma nova página
  
  pdf.addPage();
  let yPosition2 = 3
  //imagem cabecalho
  const imageUrl2 = logo;
  const leftMargin2 = 10; // Margem esquerda
  const imageWidth2 = 42;
  const imageHeight2 = 12;
  pdf.addImage(imageUrl2, 'PNG', leftMargin2, yPosition2, imageWidth2, imageHeight2);

  //yPosition2 += 2; // Adicione algum espaçamento abaixo do título
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const titulo3 = 'III. PARTICIPANTES'
  pdf.text(titulo3, margin, yPosition2 + imageHeight + 12, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte
   //tabela 3
   const columns3 = ['', ''];
   const rows2 = [
     ['Escriturador\nSingulare Corretora de Titulos e Valores Mobiliarios S.A.','CNPJ/MF\n62.285.390/0001-40'],
     // Adicione mais linhas conforme necessário
   ];
   const ytable = 33
  //Tabela 1
   pdf.autoTable({
   //  head: [columns3],
     body: rows2,
     startY:  ytable, // Posição inicial do eixo y
     theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
     styles: {
      fontSize: 11, // Tamanho da fonte da tabela
      textColor: '#00000',
     }
     
   });
 
   yPosition2 += 20; // Adicione algum espaçamento abaixo do título
   pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const titulo4 = '1. OBJETO'
  pdf.text(titulo4, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte

  yPosition2 += 7; // Adicione algum espaçamento abaixo do título
  const texto4 = `O presente Termo Constitutivo tem por objeto a ${dados.dados.emissao} emissão de notas comerciais, não conversíveis, em série única, para colocação privada, da Emissora (“Emissão” e “Notas Comerciais”, respectivamente), representativas de promessa de pagamento em dinheiro, de acordo com as características, termos e condições estabelecidos neste Termo Constitutivo, nos termos da Lei nº 6.385, de 7 de dezembro de 1976, conforme alterada e da Lei 14.195.`
  pdf.text(texto4, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition2 += 26; // Adicione algum espaçamento abaixo do título

 pdf.setFont('helvetica', 'bold'); // Estilo da fonte
 const titulo5 = '2. CONDIÇÕES PRECEDENTES'
  pdf.text(titulo5, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });
 pdf.setFont('helvetica', 'normal'); // Estilo da fonte

  yPosition2 += 9; // Adicione algum espaçamento abaixo do título
  const texto6 = 'A Emissão, está condicionada, nos termos do artigo 125 da Lei 10.406, de 10 de janeiro de 2002, conforme alterada, à verificação do cumprimento dos seguintes atos (“Condições Precedentes”):'
  pdf.text(texto6, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });

  yPosition2 += 12; // Adicione algum espaçamento abaixo do título
  const texto7 = 'a. perfeita formalização, pela Emissora e demais partes signatárias, do Termo Constitutivo e do Boletim de Subscrição (conjuntamente, os “Documentos da Operação”), bem como a verificação dos poderes dos representantes dessas partes e eventuais aprovações societárias necessárias à celebração desses documentos'
  pdf.text(texto7, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });

  yPosition2 += 18; // Adicione algum espaçamento abaixo do título 
  const texto8 = 'b. recebimento, pela Emissora, dos Boletins de Subscrição devidamente formalizados, cujo modelo segue anexo a este Termo Constituivo no Anexo I.'
  pdf.text(texto8, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });

  yPosition2 += 13; // Adicione algum espaçamento abaixo do título
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const titulo6 = '3 GARANTIAS'
  pdf.text(titulo6, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte

  yPosition2 += 7; // Adicione algum espaçamento abaixo do título
  const texto9 = 'Garantia Fidejussória'
  pdf.text(texto9, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });

  yPosition2 = 102 // Adicione algum espaçamento abaixo do título
  let texto10
  
  emissor.avalistas.map(function(avalista,index){
    texto10 =  `${avalista.aNome}, brasileiro, ${avalista.aEstadoCivil}, ${avalista.aProfissao}, nascido em ${avalista.aDataNascimento} inscrito no CPF nº: ${avalista.aCpf} portador da Carteira Identidade n.º ${avalista.aRg} residente e domiciliado na ${avalista.aRua}, ${avalista.aNumero}, ${avalista.aBairro} -  ${avalista.aEstado}, CEP ${avalista.aCep} (“Avalista”) no presente Termo Constitutivo, em caráter irrevogável e irretratável, na condição de avalista, principais pagadores e responsáveis solidários com relação às Obrigações Garantidas. `
    yPosition2 += 25; // Adicione algum espaçamento abaixo do título
    pdf.text(texto10, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });
  })
  //pdf.text(texto10, margin, yPosition2 + imageHeight + 20, { maxWidth: containerWidth });



 //Adiciona uma nova página
  ////*********************************Pagina 03***************************************************** */
 pdf.addPage();
 let yPosition3 = 3
 //imagem cabecalho
 const imageUrl3 = logo;
 const leftMargin3 = 10; // Margem esquerda
 const imageWidth3 = 42;
 const imageHeight3 = 12;
 pdf.addImage(imageUrl3, 'PNG', leftMargin3, yPosition3, imageWidth3, imageHeight3);

  yPosition3 += 2
 const  texto11 =  `O Avalista, na condição de devedor solidário e principal pagador, juntamente com a Emissora, perante o Titular, para o adimplemento das Obrigações Garantidas, assinam o presente Termo Constitutivo e declaram estarem cientes da presente garantia, aceitando todos os termos, condições e responsabilidades que daí advenham, sem a existência de qualquer benefício de ordem entre a Emissora e o Avalista.`
 pdf.text(texto11, margin, yPosition3 + imageHeight + 12, { maxWidth: containerWidth });
 
 yPosition3 += 17; // Adicione algum espaçamento abaixo do título
 const  texto12 =  `O presente Aval entrará em vigor na Data de Emissão e permanecerá válido enquanto existirem Obrigações Garantidas, extinguindo-se imediata e automaticamente mediante seu integral cumprimento.`
 pdf.text(texto12, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 
 yPosition3 += 12; // Adicione algum espaçamento abaixo do título
 const  texto13 =  `O Aval aqui previsto considera-se prestado a título oneroso, de forma que o Avalista possui interesse econômico no resultado da operação, beneficiando-se indiretamente da mesma.`
 pdf.text(texto13, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 
 yPosition3 += 12; // Adicione algum espaçamento abaixo do título
 const  texto14 =  `Uma vez declarado o vencimento antecipado das Notas Comerciais, cabe ao Titular requerer a execução judicial ou extrajudicial, do Aval. O Aval poderá ser excutido e exigido pela mesma quantas vezes forem necessárias para o integral pagamento das obrigações garantidas pelo Aval contra o Avalista.`
 pdf.text(texto14, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 
yPosition3 += 15; // Adicione algum espaçamento abaixo do título
 const  texto15 =  `O Avalista deverá pagar as Obrigações Garantidas em até 2 (dois) Dias Úteis contados da data do respectivo inadimplemento da Emissora.`
 pdf.text(texto15, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 
 yPosition3 += 12; // Adicione algum espaçamento abaixo do título
 const  texto16 =  `Fica desde já certo e ajustado que a inobservância, pelo Titular, dos prazos para execução do Aval não ensejará, sob hipótese nenhuma, perda de qualquer direito ou faculdade aqui previsto, podendo o Aval ser excutido e exigido pelo Titular, judicial ou extrajudicialmente, quantas vezes forem necessárias até a integral liquidação das Obrigações Garantidas.`
 pdf.text(texto16, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 
 yPosition3 += 20;
 // Adicione algum espaçamento abaixo do título
 const  texto17 =  `Os pagamentos que vierem a ser realizados pelo Avalista com relação às Notas Comerciais serão realizados de modo que o Titular receba do Avalista os valores que lhes seriam entregues caso esses pagamentos tivessem sido realizados pela Emissora, não cabendo ao Avalista realizar qualquer dedução que não seria realizada pela Emissora, caso a Emissora tivesse realizado o respectivo pagamento.`
 pdf.text(texto17, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 21;
 pdf.setFont('helvetica', 'bold'); // Estilo da fonte
 const titulo7 = '4. VENCIMENTO ANTECIPADO'
 pdf.text(titulo7, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 pdf.setFont('helvetica', 'normal'); // Estilo da fonte

 yPosition3 += 7; // Adicione algum espaçamento abaixo do título
 const texto18 = 'a. Se ocorrer inadimplemento de qualquer obrigação assumida pela Emissora em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange ao pagamento das parcelas devidas em decorrências da celebração deste Termo Constitutivo e emissão das Notas Comerciais;'
 pdf.text(texto18, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 15; // Adicione algum espaçamento abaixo do título
 const textoExtra = 'b. Se for comprovada a falsidade de qualquer declaração, informação ou documento que houver sido respectivamente firmada, prestada ou entregue pela Emissora;'
 pdf.text(textoExtra, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 12; // Adicione algum espaçamento abaixo do título
 const textoExtra1 = 'c. Se a Emissora sofrer quaisquer medidas judiciais ou extrajudiciais, que por qualquer forma, possam afetar negativamente os créditos das Notas Comerciais; e'
 pdf.text(textoExtra1, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 12; // Adicione algum espaçamento abaixo do título
 const textoExtra2 = 'd. Se, sem o expresso consentimento dos credores das Notas Comerciais ocorrer a transferência a terceiros dos direitos e obrigações da Emissora previstos neste Termo Constitutivo'
 pdf.text(textoExtra2, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 12; // Adicione algum espaçamento abaixo do título
 const textoExtra3 = 'e. Se ocorrer a transferência a terceiros dos direitos e obrigações da Emissora, previstos neste Termo Constitutivo.'
 pdf.text(textoExtra3, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

yPosition3 += 12; // Adicione algum espaçamento abaixo do título

pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const titulo8 = '5. DAS DECLARAÇÕES'
 pdf.text(titulo8, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });
 pdf.setFont('helvetica', 'normal'); // Estilo da fonte

 yPosition3 += 7; // Adicione algum espaçamento abaixo do título
 const texto19 = 'A Emissora declara e garante que:'
 pdf.text(texto19, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 9; // Adicione algum espaçamento abaixo do título
 const texto20 = 'a. Possui plena capacidade e legitimidade para celebrar o presente Termo Constitutivo, realizar todas as operações e cumprir todas as obrigações assumidas tendo tomado todas as medidas de natureza societária, conforme aplicável, e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas;'
 pdf.text(texto20, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 19; // Adicione algum espaçamento abaixo do título 
 const texto21 = ' b. A celebração deste Termo Constitutivo e o cumprimento das obrigações nele dispostos: (a) não violam qualquer disposição contida nos seus documentos societários, conforme aplicável; (b) não violam qualquer lei,regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva parte esteja vinculada; (c) não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros;'
 pdf.text(texto21, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 20; // Adicione algum espaçamento abaixo do título 
 const texto22 = 'c. Este Termo Constitutivo é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível,de acordo com os seus termos;'
 pdf.text(texto22, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition3 += 10; // Adicione algum espaçamento abaixo do título 
 const texto23 = ' d. Está apta a cumprir as obrigações ora previstas neste Termo Constitutivo e agirá em relação às mesmas de boa-fé e com lealdade;'
 pdf.text(texto23, margin, yPosition3 + imageHeight + 20, { maxWidth: containerWidth });


  //Adiciona uma nova página
  ////*********************************Pagina 04***************************************************** */
  pdf.addPage();
  let yPosition4 = 3
  //imagem cabecalho
  const imageUrl4 = logo;
  const leftMargin4 = 10; // Margem esquerda
  const imageWidth4 = 42;
  const imageHeight4 = 12;
  pdf.addImage(imageUrl4, 'PNG', leftMargin4, yPosition4, imageWidth4, imageHeight4);

 
  const texto25 = ' e. Não se encontra em estado de necessidade ou sob coação para celebrar este Termo Constitutivo e/ou quaisquer contratos e compromissos a ela relacionados e acessórios; e'
  pdf.text(texto25, margin, yPosition4 + imageHeight + 12, { maxWidth: containerWidth });
 
  yPosition4 += 6; // Adicione algum espaçamento abaixo do título 
  const texto26 = 'f. É devidamente estruturada, qualificada e capacitada para entender a estrutura financeira e jurídica objeto deste Termo Constitutivo.'
  pdf.text(texto26, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
  yPosition4 += 12; // Adicione algum espaçamento abaixo do título 
  
pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const titulo9 = '6. DO ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS'
 pdf.text(titulo9, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 pdf.setFont('helvetica', 'normal'); // Estilo da fonte

 yPosition4 += 7; // Adicione algum espaçamento abaixo do título
 const texto24 = 'Na hipótese de inadimplemento ou mora dos valores devidos no âmbito deste Termo Constitutivo, a Emissora estará obrigada a pagar ao titular, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:'
 pdf.text(texto24, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition4 += 15; // Adicione algum espaçamento abaixo do título
 const texto27 = 'a. Juros remuneratórios nos mesmos percentuais das taxas constantes neste Termo Constitutivo, calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento;'
 pdf.text(texto27, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition4 += 11; // Adicione algum espaçamento abaixo do título 
 const texto28 = 'b. Juros de mora à razão de 1% a.m. (um por cento ao mês), calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento pela Emissora;'
 pdf.text(texto28, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition4 += 12; // Adicione algum espaçamento abaixo do título 
 const texto29 = 'c. Multa contratual, de natureza não compensatória, de 2% (dois por cento) incidente sobre o montante total atualizado (incluindo juros remuneratórios e juros de mora) do valor devido e não pago; e'
 pdf.text(texto29, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition4 += 13; // Adicione algum espaçamento abaixo do título 
 const texto30 = 'd- Na hipótese do Titular vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito deste Termo Constitutivo, as despesas de cobrança administrativa, limitadas a 10% (dez por cento) sobre o valor do saldo devedor deste Termo Constitutivo e, havendo procedimento judicial, custas processuais e honorários advocatícios, estes fixados judicialmente.'
 pdf.text(texto30, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 22; // Adicione algum espaçamento abaixo do título 

 pdf.setFont('helvetica', 'bold'); // Estilo da fonte
 const titulo10 = '7. DISPOSIÇÕES GERAIS'
 pdf.text(titulo10, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 7; // Adicione algum espaçamento abaixo do título
 pdf.setFont('helvetica', 'normal'); // Estilo da fonte

 const texto33 = 'Da não Conversibilidade'
 pdf.text(texto33, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition4 += 7; // Adicione algum espaçamento abaixo do título
 const texto34 = 'As notas objeto desta Emissão não são conversíveis em quotas Emissora.'
 pdf.text(texto34, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 7; // Adicione algum espaçamento abaixo do título

 const texto35 = 'Renúncia'
 pdf.text(texto35, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 7; // Adicione algum espaçamento abaixo do título 

 const texto36 = `Não se presume a renúncia a qualquer dos direitos decorrentes das Notas Comerciais. Desta forma, nenhum atraso, omissão ou liberalidade no exercício de qualquer direito, faculdade ou prerrogativa que caiba ao Titular, em razão de qualquer inadimplemento da Emissora, prejudicará o exercício de tais direitos, faculdades ou remédios, ou será interpretado como constituindo uma renúncia aos mesmos ou concordância com tal inadimplemento, nem constituirá novação ou modificação de quaisquer outras obrigações assumidas pela Emissora neste instrumento, ou precedente no tocante a qualquer outro inadimplemento ou atraso.`
 pdf.text(texto36, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 35; // Adicione algum espaçamento abaixo do título 

 const texto37 = 'Título Executivo Extrajudicial e Execução Específica'
 pdf.text(texto37, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 7; // Adicione algum espaçamento abaixo do título 

 const texto38 = `Este instrumento e as Notas Comerciais constituem títulos executivos extrajudiciais, nos termos do artigo 48 da Lei 14.195 de 2021, sem prejuízo do direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste instrumento.`
 pdf.text(texto38, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 17; // Adicione algum espaçamento abaixo do título 

 const texto39 = 'Da Contratação dos Prestadores de Serviços da Emissão – Outorga de Poderes'
 pdf.text(texto39, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 7; // Adicione algum espaçamento abaixo do título 
 const texto40 = `A Emissora, neste ato, em caráter irrevogável e irretratável, autoriza o Estruturador a representá-la perante os prestadores de serviço envolvidos na Emissão, inclusive contratá-los em seu nome, desde que a obrigatoriedade de pagamento seja do próprio Estruturador.`
 pdf.text(texto40, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 15; // Adicione algum espaçamento abaixo do título 

 const texto41 = 'Comunicações'
 pdf.text(texto41, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 7; // Adicione algum espaçamento abaixo do título 
 const texto42 = `As notificações, instruções e comunicações serão consideradas entregues quando recebidas sob protocolo ou com “aviso de recebimento” expedido pela Empresa Brasileira de Correios, ou por telegrama nos endereços do preâmbulo. As comunicações enviadas por correio eletrônico serão consideradas recebidas na data de seu envio, desde que seu recebimento seja confirmado por meio de recibo emitido pelo remetente (recibo emitido pela máquina utilizada pelo remetente). A mudança de qualquer dos endereços acima deverá ser imediatamente comunicada às demais Partes pela Parte que tiver seu endereço alterado.`
 pdf.text(texto42, margin, yPosition4 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition4 += 20; // Adicione algum espaçamento abaixo do título 

 //Adiciona uma nova página
  ////*********************************Pagina 05***************************************************** */
  pdf.addPage();
  let yPosition5 = 3
  //imagem cabecalho
  const imageUrl5 = logo;
  const leftMargin5 = 10; // Margem esquerda
  const imageWidth5 = 42;
  const imageHeight5 = 12;
  pdf.addImage(imageUrl5, 'PNG', leftMargin5, yPosition5, imageWidth5, imageHeight5);

 
  const texto43 = 'Irrevogabilidade'
  pdf.text(texto43, margin, yPosition5 + imageHeight + 12, { maxWidth: containerWidth });
 
  yPosition5 += 3; // Adicione algum espaçamento abaixo do título 
  const texto44 = 'As Notas Comerciais e o presente instrumento são celebrados em caráter irrevogável e irretratável, obrigando as partes e seus sucessores a qualquer título.'
  pdf.text(texto44, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });
  yPosition5 += 12; // Adicione algum espaçamento abaixo do título 

const titulo11 = 'Cômputo do Prazo'
 pdf.text(titulo11, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 7; // Adicione algum espaçamento abaixo do título
 const texto45 = 'Exceto se de outra forma especificamente disposto neste instrumento, os prazos estabelecidos no presente instrumento serão computados de acordo com a regra prescrita no artigo 132 do Código Civil, sendo excluído o dia do começo e incluído o do vencimento'
 pdf.text(texto45, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 16; // Adicione algum espaçamento abaixo do título
 const texto47 = 'Assinatura Eletrônica'
 pdf.text(texto47, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 8; // Adicione algum espaçamento abaixo do título
 const texto48 = 'Para os fins do artigo 10, parágrafo 2º, da Medida Provisória nº 2.200-2, de 24 de agosto de 2001, as Partes acordam e aceitam que este instrumento e qualquer aditamento podem ser formalizados mediante aposição de (i) assinaturas digitais, com processo de certificação disponibilizada pela Infraestrutura de Chaves Públicas Brasileira – ICP-Brasil ou (ii) assinaturas eletrônicas que utilizem certificados não emitidos pela ICP-Brasil para comprovação da autoria e integridade de documentos em forma eletrônica, sendo ambas as formas de assinatur  expressamente reconhecidas pelas Partes como válidas, nos termos do Artigo 10, §2º da Medida Provisória nº 2.200-2, de 24 de agosto de 2001.'
 pdf.text(texto48, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 35; // Adicione algum espaçamento abaixo do título
 const texto49 = 'Data de Integralização'
 pdf.text(texto49, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 7; // Adicione algum espaçamento abaixo do título
 const texto51 = 'Para os fins deste Termo Constitutivo, Data de Integralização é(são) a(s) data(s) em que a Emissora receberá o crédito decorrente das Notas Comerciais, subscrita(s) por seus(s) respectivos(s) titular(es).'
 pdf.text(texto51, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 13; // Adicione algum espaçamento abaixo do título
 const texto52 = 'Boa-fé e Equidade'
 pdf.text(texto52, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 7; // Adicione algum espaçamento abaixo do título
 const texto53 = 'As Partes declaram, mútua e expressamente, que este instrumento foi celebrado respeitando-se os princípios de probidade e de boa-fé, por livre, consciente e firme manifestação de vontade das Partes e em perfeita relação de equidade.'
 pdf.text(texto53, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 16; // Adicione algum espaçamento abaixo do título
 const texto54 = 'Lei Aplicável'
 pdf.text(texto54, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 7; // Adicione algum espaçamento abaixo do título
 const texto55 = 'Este instrumento é regido pelas Leis da República Federativa do Brasil.'
 pdf.text(texto55, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 7; // Adicione algum espaçamento abaixo do título
 const texto56 = 'Foro'
 pdf.text(texto56, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 7; // Adicione algum espaçamento abaixo do título
 const texto57 = `Fica eleito o foro da Comarca da Cidade de ${emissor.cidade} Estado de ${estadoPorExtenso(emissor.estado)}, para dirimir quaisquer dúvidas ou controvérsias oriundas deste instrumento, com renúncia a qualquer outro, por mais privilegiado que seja.`
 pdf.text(texto57, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 17; // Adicione algum espaçamento abaixo do título
 const texto58 = `                                                                 ${investidor.cidade}, ${dataHoje}                                                                                           `
 pdf.text(texto58, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 12; // Adicione algum espaçamento abaixo do título
 const texto59 = '                                  ___________________________________________________\n \n                                                    Laís e Marcos Vinicius Pães e Doces Ltda \n                                                                  Na qualidade de Emissora  '
 pdf.text(texto59, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

 yPosition5 += 40; // Adicione algum espaçamento abaixo do título
 const texto60 = 'TESTEMUNHAS:'
 pdf.text(texto60, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });
 yPosition5 += 12; // Adicione algum espaçamento abaixo do título

 const texto61 = '     ____________________________________________________________________________________\n \n          Nome: César de França Galvão Neto                                           Nome: Rosana Winterink                        \n                          CPF:298.608.778.74                                                             CPF: 222.201.218-01 '
 pdf.text(texto61, margin, yPosition5 + imageHeight + 20, { maxWidth: containerWidth });

  //Adiciona uma nova página
  ////*********************************Pagina 06***************************************************** */
  pdf.addPage();
  let yPosition6 = 3
  //imagem cabecalho
  const imageUrl6 = logo;
  const leftMargin6 = 10; // Margem esquerda
  const imageWidth6 = 42;
  const imageHeight6 = 12;
  pdf.addImage(imageUrl6, 'PNG', leftMargin6, yPosition6, imageWidth6, imageHeight6);
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const texto62 = '                  ANEXO I – BOLETIM DE SUBSCRIÇÃO DAS NOTAS COMERCIAIS:                              '
  pdf.text(texto62, margin, yPosition6 + imageHeight + 12, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte

  yPosition6 += 35; // Adicione algum espaçamento abaixo do título
  pdf.rect(15, yPosition6, 180, 11); // (x, y, largura, altura)

  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const texto63 = `      BOLETIM DE SUBSCRIÇÃO DA 1ª (PRIMEIRA) EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE                    ÚNICA, PARA COLOCAÇÃO PRIVADA, DA ${emissor.razaoSocial}`
  pdf.text(texto63, margin, 10 + imageHeight + 20, { maxWidth: containerWidth });
 pdf.setFont('helvetica', 'normal'); // Estilo da fonte

  yPosition6 = 50
  pdf.rect(15, yPosition6, 180, 11); // (x, y, largura, altura)
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const texto64 = `                                                 CARACTERÍSTICAS DA EMISSÃO                                                 `
  pdf.text(texto64, margin, 25 + imageHeight + 20, { maxWidth: containerWidth });
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte
  
  yPosition6 = 63 //fimei um marco para baixo
  pdf.rect(15, yPosition6, 180, 25); // (x, y, largura, altura)
  yPosition6 += 5
  
  const texto65 = `                                      Emissão, pela ${emissor.razaoSocial}  
        pessoa jurídica situada na ${emissor.rua}, N° ${emissor.numero}, ${emissor.cidade}/${emissor.estado} – CEP: ${emissor.cep} 
        inscrita no CNPJ/MF sob o nº ${emissor.cnpj} ("Companhia"), de 1 notas comerciais, 
        com valor nominal  unitário de ${valorTotal} e vencimento em ${dataDoVencimento}, da sua ${dados.dados.emissao} 
                                      emissão ("Notas Comerciais" e "Emissão"). `
  pdf.text(texto65, margin, yPosition6 , { maxWidth: containerWidth });

  yPosition6 += 22
  pdf.rect(15, yPosition6, 180, 8); // (x, y, largura, altura)
  yPosition6 += 5
  pdf.setFont('helvetica', 'bold'); // Estilo da fonte
  const texto66 = `                                                 QUALIFICAÇÃO DO SUBSCRITOR `
  pdf.text(texto66, margin, yPosition6 , { maxWidth: containerWidth })
pdf.setFont('helvetica', 'normal'); // Estilo da fonte
yPosition6 += 3
pdf.rect(15, yPosition6, 180, 10);
const borderWidth = 10; // Ajuste conforme necessário
const xRight = 100 + 10 - borderWidth; // Posição x da borda interna à direita
pdf.line(xRight, yPosition6 + borderWidth, xRight, yPosition6 );
yPosition6 += 6
const texto67 = `      Nome / Razão Social: ${investidor.razaoSocial}            CNPJ /MF: ${investidor.cnpj} `
pdf.text(texto67, margin, yPosition6 , { maxWidth: containerWidth })

yPosition6 += 4
pdf.rect(15, yPosition6, 180, 10);
const borderWidth1 = 10; // Ajuste conforme necessário
const xRight1 = 100 + 10 - borderWidth1; // Posição x da borda interna à direita
pdf.line(xRight1, yPosition6 + borderWidth1, xRight1, yPosition6 );
const xRight2 =+ xRight1 +40
pdf.line(xRight2, yPosition6 + borderWidth1, xRight2, yPosition6 );
yPosition6 += 5
const texto68 = `      Endereço:  ${investidor.rua}                                    N°:  ${investidor.numero}                          Complemento:  ${investidor.complemento}`
pdf.text(texto68, margin, yPosition6 , { maxWidth: containerWidth })
///*
yPosition6 += 5
pdf.rect(15, yPosition6, 180, 10);
const borderWidth2 = 10; 
const xRight3 = 70 + 10 - borderWidth1; 
pdf.line(xRight3, yPosition6 + borderWidth1, xRight3, yPosition6 ); //1
const xRight4 =+ xRight3 +50
pdf.line(xRight4, yPosition6 + borderWidth1, xRight4, yPosition6 ); //2
const xRight5 =+ xRight4 +10
pdf.line(xRight5, yPosition6 + borderWidth1, xRight5, yPosition6 ); //3
const xRight6 =+ xRight5 +15
pdf.line(xRight6, yPosition6 + borderWidth1, xRight6, yPosition6 ); //4
//const xRight7 =+ xRight6 +50
//pdf.line(xRight7, yPosition6 + borderWidth1, xRight7, yPosition6 ); //5
yPosition6 += 6
const texto70 = `        ${investidor.bairro}                                   ${investidor.cidade}                               ${investidor.estado}    Brasil    Tel: ${investidor.telefone}`
pdf.text(texto70, margin, yPosition6 , { maxWidth: containerWidth })

/******** */
yPosition6 += 10
pdf.rect(15, yPosition6, 180, 8); // (x, y, largura, altura)
yPosition6 += 5

pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const texto72 = `                                                 NOTAS COMERCIAIS SUBSCRITAS `
pdf.text(texto72, margin, yPosition6 , { maxWidth: containerWidth })
pdf.setFont('helvetica', 'normal'); // Estilo da fonte

yPosition6 += 4
pdf.rect(15, yPosition6, 180, 10);
const borderWidth5 = 10; // Ajuste conforme necessário
const xRight7 = 50 + 10 - borderWidth5; // Posição x da borda interna à direita
pdf.line(xRight7, yPosition6 + borderWidth5, xRight7, yPosition6 );
const xRight8 =+ xRight1 +30
pdf.line(xRight8, yPosition6 + borderWidth5, xRight8, yPosition6 );
yPosition6 += 5
const texto71 = `      Quantidade: 1         Preço Total Unitário: ${allInfoArray[13]}                Valor em : ${allInfoArray[13]} `
pdf.text(texto71, margin, yPosition6 , { maxWidth: containerWidth })

yPosition6 += 10
pdf.rect(15, yPosition6, 180, 12);
yPosition6 += 5
const texto73 = `      O Preço Total Unitário será mantido em cada data de integralização, caso distintas. A rentabilidade                                                   apenas se inicia após a Data de Integralização.
 `
pdf.text(texto73, margin, yPosition6 , { maxWidth: containerWidth })

yPosition6 += 10
pdf.rect(15, yPosition6, 180, 8); // (x, y, largura, altura)
yPosition6 += 5
pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const texto74 = `                                                 FORMA SUBSCRIÇÃO E INTEGRALIZAÇÃO `
pdf.text(texto74, margin, yPosition6 , { maxWidth: containerWidth })
pdf.setFont('helvetica', 'normal'); // Estilo da fonte
yPosition6 += 3
pdf.rect(15, yPosition6, 180, 21);
const borderWidth6 = 21; // Ajuste conforme necessário
const xRight9 = 100 + 10 - borderWidth; // Posição x da borda interna à direita
pdf.line(xRight9, yPosition6 + borderWidth6, xRight9, yPosition6 );
yPosition6 += 6
const texto75 = `              MEIO DE INTEGRALIZAÇÃO: \n      ${emissor.meioPagamento}             `
pdf.text(texto75, margin, yPosition6 , { maxWidth: containerWidth })
const texto76 = `                                                                                                DATA DE INTEGRALIZAÇÃO: \n                                                                                  A efetiva integralização deverá ser realizada em \n                                                                                  até 30 (trinta) dias corridos,contados da data de                                                                                             assinatura do presente Boletim de Subscrição.             `
pdf.text(texto76, margin, yPosition6 , { maxWidth: containerWidth })

yPosition6 += 25
pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const texto77 = `                                                          CONTA BANCÁRIA DA EMISSORA`
pdf.text(texto77, margin, yPosition6 , { maxWidth: containerWidth })
pdf.setFont('helvetica', 'normal'); // Estilo da fonte
yPosition6 += 5

const columns5 = ['Banco', 'N° do Banco','N° da Agência','N° da Conta'];
const rows5 = [
  [`${emissor.banco}` ,`${emissor.bankNumber}`,`${emissor.agency}`,`${emissor.conta}`],
  // Adicione mais linhas conforme necessário
];

pdf.autoTable({
  head: [columns5],
  body: rows5,
  startY:  yPosition6 += 2, // Posição inicial do eixo y
  theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
  headStyles: {
    fillColor: [169, 169, 169],
    textColor: [0, 0, 0]       // Cor do texto da coluna
  },
  styles: {
    fontSize: 11, // Tamanho da fonte da tabela
    textColor: '#00000',
    // Adicione outros estilos conforme necessário
  },
  
});
yPosition6 += 25
pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const texto78 = `                                                          CONTA BANCÁRIA DO ESCRITURADOR`
pdf.text(texto78, margin, yPosition6 , { maxWidth: containerWidth })
yPosition6 += 5
pdf.setFont('helvetica', 'normal'); // Estilo da fonte
const columns6 = ['Banco', 'N° do Banco','N° da Agência','N° da Conta'];
const rows6 = [
  [`Singulare` ,`363`,`0001`,`45-1`],
  // Adicione mais linhas conforme necessário
];

pdf.autoTable({
  head: [columns6],
  body: rows6,
  startY:  yPosition6 += 5, // Posição inicial do eixo y
  theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
  headStyles: {
    fillColor: [169, 169, 169],
    textColor: [0, 0, 0]       // Cor do texto da coluna
  },
  styles: {
    fontSize: 11, // Tamanho da fonte da tabela
    textColor: '#00000',
    // Adicione outros estilos conforme necessário
  },
  
});
 //Adiciona uma nova página
  ////*********************************Pagina 07***************************************************** */
  pdf.addPage();
  let yPosition7 = 4
  //imagem cabecalho
  const imageUrl7 = logo;
  const leftMargin7 = 10; // Margem esquerda
  const imageWidth7 = 42;
  const imageHeight7 = 12;
  //pdf.addImage(imageUrl7, 'PNG', leftMargin7, yPosition7, imageWidth7, imageHeight7);

yPosition7 += 25
pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const texto79 = `                                                          CONTA BANCÁRIA DO SUBSCRITOR`
pdf.text(texto79, margin, yPosition7 , { maxWidth: containerWidth })
pdf.setFont('helvetica', 'normal'); // Estilo da fonte

const columns7 = ['Banco', 'N° do Banco','N° da Agência','N° da Conta'];
const rows7 = [
  [`${investidor.banco}` ,`${investidor.numeroDoBanco}`,`${investidor.agencia}`,`${investidor.conta}`],
  // Adicione mais linhas conforme necessário
];

pdf.autoTable({
  head: [columns7],
  body: rows7,
  startY:  yPosition7 += 7, // Posição inicial do eixo y
  theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
  headStyles: {
    fillColor: [169, 169, 169],
    textColor: [0, 0, 0]       // Cor do texto da coluna
  },
  styles: {
    fontSize: 11, // Tamanho da fonte da tabela
    textColor: '#00000',
    // Adicione outros estilos conforme necessário
  },
  
});

yPosition7 += 23
pdf.rect(15, yPosition7, 180, 52);
yPosition7 += 6
const texto80 = `      Declaro, para todos os fins, que estou de acordo com as condições expressas no presente boletim,              bem como declaro ter obtido exemplar do Termo Constitutivo.             `
pdf.text(texto80, margin, yPosition7 , { maxWidth: containerWidth })

yPosition7 += 12
const texto81 = `                                                                     ${investidor.cidade}, ${dataHoje}                                                `
pdf.text(texto81, margin, yPosition7 , { maxWidth: containerWidth })


yPosition7 += 10
const texto82 = `          __________________________________________________________________________`
pdf.text(texto82, margin, yPosition7 , { maxWidth: containerWidth })

yPosition7 += 10
const texto83 = `                       _________________________________________________________ \n \n                                           Assinatura do Subscritor ou Representante Legal`
pdf.text(texto83, margin, yPosition7 , { maxWidth: containerWidth })

yPosition7 += 27
//let  yPosition8 = yPosition7
pdf.setFont('helvetica', 'bold'); // Estilo da fonte
const texto86 = `                                                                           Anexo ll                          `
pdf.text(texto86, margin, yPosition7 , { maxWidth: containerWidth })
pdf.setFont('helvetica', 'normal'); // Estilo da fonte
yPosition7 += 5
const columns = [
  'Período', 'Vencimento', 'Parcela', 'Amortização' ,'Juros','Saldo Devedor'];
//const now = new Date();
let rowsArray5 = [
  ['0', `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()}`,     'R$ 0',     'R$ 0',    'R$ 0',     allInfoArray[13]],
 ]
let arrayAux=[]
 calcData.map((data, index, array) => {
   arrayAux = [data[0].toString(),`${data[1] < 10 ? `0${data[1]}` : data[1]}/${data[2] === '00' ? 12 : data[2]}/${data[3]}`,data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })]
   rowsArray5.push(arrayAux)
  
});

pdf.autoTable({
  head: [columns],
  body: rowsArray5,
  startY: yPosition7 + 2,
  theme: 'grid',
  headStyles: {
    fillColor: [169, 169, 169],
    textColor: [0, 0, 0],     // Cor do texto da coluna
    halign: 'center' 
  },
  styles: {
    table: {
      lineWidth: 0.5,
    },
    fontSize: 11, // Tamanho da fonte da tabela
    textColor: '#00000',
  },
  columnStyles: {
    0: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
    1: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
    2: { halign: 'right' }, // Alinhar a coluna "Parcela" à esquerda
    3: { halign: 'right' }, // Alinhar a coluna "Parcela" à esquerda
    4: { halign: 'right' }, // Alinhar a coluna "Parcela" à esquerda
    5: { halign: 'right' }, // Alinhar a coluna "Parcela" à esquerda
    6: { halign: 'right' }, // Alinhar a coluna "Parcela" à esquerda
  },
});
yPosition7 = pdf.autoTable.previous.finalY + 10;
if(garantia){
    pdf.setFont('helvetica', 'bold'); // Estilo da fonte
    const texto84 = `                                                               Anexo lll - Garantia(s)                         `
    pdf.setFont('helvetica', 'normal'); // Estilo da fonte
    pdf.text(texto84, margin, yPosition7, { maxWidth: containerWidth })
    const columns4 = ['Tipo', 'Quantidade', 'Proprietário ' ,'Natureza','CPF/CNPJ','Descrição'];
    let arrayAux2=[]
    let rowsArray6 = []
    
      garantia.map((data, index, array) => {
        arrayAux2 = [data.gTipo,data.gQtd,data.gProp,data.gNat,data.gCPF,data.gDesc]
        rowsArray6.push(arrayAux2)
        //yPosition7 += 5
        
      });
    pdf.autoTable({
      head: [columns4],
      body: rowsArray6,
      startY: yPosition7 + 10,
      theme: 'grid',
      bodyStyles: {
        fillColor: [211, 211, 211], // Cinza para o corpo da tabela
        textColor: [0, 0, 0],       // Cor do texto no corpo da tabela            // Centralizar o texto no corpo da tabela
      },
      styles: {
        head: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          halign: 'center',
          valign: 'middle',
          
        },
      
        body: {
          textColor: [0, 0, 0],
        },
        table: {
          lineWidth: 0.5,
        },
        fontSize: 11, // Tamanho da fonte da tabela
        textColor: '#00000',
      },
      columnStyles: {
        0: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
        1: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
        2: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
        3: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
        4: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
        5: { halign: 'center' }, // Alinhar a coluna "Parcela" à esquerda
      },
      headStyles: {
        fillColor: [169, 169, 169],
        textColor: [0, 0, 0],     // Cor do texto da coluna
        halign: 'center' 
      },
    });

    //Imagens garantia
    garantia.map((data, index) => {
     if (data && data.gDoc2) {
        pdf.addPage();
        const pageWidth = pdf.internal.pageSize.getWidth(); // Largura da página
        const pageHeight = pdf.internal.pageSize.getHeight(); // Altura da página

        const margin = 0.1 * pageWidth; // 10% da largura da página para margens

        // Defina as dimensões da imagem para ocupar 80% da largura e altura da página
        const imageWidth7 = 0.8 * pageWidth;
        const imageHeight7 = 0.8 * pageHeight;

        const imageUrl8 = data.gDoc2;
        const leftMargin7 = margin; // Margem esquerda
        const topMargin7 = margin; // Margem superior

      //  pdf.addImage(imageUrl8, 'PNG', leftMargin7, topMargin7, imageWidth7, imageHeight7);
    }
    });
    //imagem cabecalho

}

  const blob = pdf.output('blob');
  onBlobReady(blob);
};

export default generatePDF;
