import React, { useEffect, useState,useRef  } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { formatCnpj, formatCpf, formatDocument, formatRg } from "../../utils/Tools";
import { formatBankAccount, formatarTelefone } from "../../pages/Investidor";
import axios from "axios";
import ReactInputMask from "react-input-mask";
import MyPDFViewer from "../../utils/pdfs/propostaModelo";
import { AiFillCloseCircle } from "react-icons/ai"
//import DevedorSolidario from "../../components/DevedorSolidario";
//import '../../styles/Emissor.css';


const PropostaModal = ({ isOpen, onClose }) => {
    const [NumeroProposta, setNProposta] = useState('');
    const [NomeSolicitante, setNomeSolicitante] = useState('');
    const [Cnpj, setCnpj] = useState('');
    const [Cpf, setCpf] = useState('');
    const [Email, setEmail]  = useState('');
    const [Responsavel, setResponsavel] = useState('');
    const [NomeEmissor, setNomeEmissor] = useState('');
    const [Serie, setSerie] = useState('');
    const [appear, setAppear] = useState(false);
    const [Amortizacao, setAmortizacao] = useState('');
    const modalRef = useRef(null);
    const navigate = useNavigate();
    
    const dados = {
        NumeroProposta:NumeroProposta,
        Solicitante:NomeSolicitante,
        Cnpj: Cnpj,
        Email: Email,
        Responsavel: Responsavel,
        Cpf:Cpf,
        Emissor:NomeEmissor,
        Serie: Serie,
        Amortizacao: Amortizacao
    }
    const handleAppear = () => {
        if(NomeSolicitante.length<2){
            alert('Por favor, insira um nome de Investidor válido.');
            return;
        }
        /** remove os traços e barrar antes da validação */
        if(Cnpj.replace(/\D/g, '').length!==14){
            alert('Por favor, insira um nome de Cnpj válido.');
            return;
        }
        if(Responsavel.length<2){
            alert('Por favor, insira um nome de Responsavel válido.');
           return;
        }
        /** remove os traços e barrar antes da validação */
        if(Cpf.replace(/\D/g, '').length!==11){
            alert('Por favor, insira um nome de Cpf válido.');
            return;
        }
        /** email com tamanho minimo a@a.c e com @ */
        if(Email.length<5 | Email.lastIndexOf("@")===0){
            alert('Por favor, insira um nome de e-mail válido.');
            return;
        }
        if (appear === false) {
            setAppear(true)
        }
       // else (setAppear(false))
        
    }
    const closeProposta = () => {
       // window.location.href = '/home';
       navigate('/home')
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Emissor Modal"
            ref={modalRef} // Adiciona a referência ao modal
        >
            <div className="modal">
                <div className="modal-content" id="proposta">
                    <button className="modalClose" onClick={onClose}>
                        X
                    </button>
                    <div className="formDocProp">
                        <div className="divTitleDoc">
                            <h1 className="h1DocProp">Dados da Proposta Comercial</h1>
                        </div>
                    {/* <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Número da Proposta:
                            </label>
                            <input
                                type="text"
                                placeholder="Nº proposta"
                                className={`inputsDoc`}
                                value={NumeroProposta}
                                onChange={(e) => { setNProposta(e.target.value) }}
                                maxLength={120} />
                        </div>*/}
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Investidor:
                            </label>
                            <input
                                type="text"
                                placeholder="Insira o nome do solicitate da proposta"
                                className={`inputsDocPop`}
                                value={NomeSolicitante}
                                onChange={(e) => { setNomeSolicitante(e.target.value) }}
                                maxLength={120}
                                required />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                CNPJ:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Apenas Números"
                                value={formatCnpj(Cnpj)}
                                onChange={(e) => { setCnpj(e.target.value) }}
                                maxLength={18}
                                required
                            />
                        </div>
                        <div className="divInputsDoc">
                        <label htmlFor="" className="labelInputsDoc">
                                Responsável:
                            </label>
                            <input
                                type="text"
                                placeholder="Insira o responsável"
                                className={`inputsDocPop`}
                                value={Responsavel}
                                onChange={(e) => { setResponsavel(e.target.value) }}
                                maxLength={120}
                                required />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                CPF:
                            </label>
                            <input
                                type="text"
                                className={`inputsDoc`}
                                placeholder="Apenas Números"
                                value={formatCpf(Cpf)}
                                onChange={(e) => { setCpf(e.target.value) }}
                                maxLength={14}
                                required
                            />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                E-mail:
                            </label>
                            <input
                                type="email"
                                className={`inputsDoc`}
                                placeholder=""
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                       {/* <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Emissor:
                            </label>
                            <input
                                type="text"
                                placeholder="Insira o nome do emissor"
                                className={`inputsDocPop`}
                                value={NomeEmissor}
                                onChange={(e) => { setNomeEmissor(e.target.value) }}
                                maxLength={120} />
                    </div>*/}
                        {/*<div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Séries:
                            </label>
                            <input
                                type="text"
                                placeholder="série"
                                className={`inputsDoc`}
                                value={Serie}
                                onChange={(e) => { setSerie(e.target.value) }}
                                maxLength={50} />
                        </div>
                        <div className="divInputsDoc">
                            <label htmlFor="" className="labelInputsDoc">
                                Amortização: 
                            </label>
                            <input
                                type="text"
                                placeholder="defina a amortização"
                                className={`inputsDoc`}
                                value={Amortizacao}
                                onChange={(e) => { setAmortizacao(e.target.value) }}
                                maxLength={50} />
                        </div>*/}
                        <button className="buttonProp" onClick={handleAppear}>Gerar Proposta</button>
                        {appear ? (
                                <div className="pdf-container active">
                                    <div onClick={handleAppear} >
                                        <AiFillCloseCircle onClick={closeProposta} size={40} className="close-button1" color="#f83939" />
                                    </div>
                                    <MyPDFViewer dadosProposta={dados} />
                                </div>
                            ) : null 
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PropostaModal;