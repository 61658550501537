import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { formatDateString } from "./TrusteeSemGarantia";
import { nascimentoAvalista } from "./SingulareSemGarantia";
import  HyphenationCallback  from "./PdfHyphenation";
import { parseDateHyphen, estadoPorExtenso } from "../Tools";
Font.registerHyphenationCallback(HyphenationCallback);

const formatCnpj = (text) => {
    if (text) {
        const cnpj = text.replace(/\D/g, '');
        let formattedCnpj = '';

        for (let i = 0; i < cnpj.length; i++) {
            if (i === 2 || i === 5) {
                formattedCnpj += '.';
            } else if (i === 8) {
                formattedCnpj += '/';
            } else if (i === 12) {
                formattedCnpj += '-';
            }
            formattedCnpj += cnpj[i];
        }

        return formattedCnpj;
    }
};

const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        padding: 30,
        display: "flex",
        flexDirection: "column",
    },
    header: {
        marginBottom: 10,
        textAlign: "center",
    },
    image: {
        width: '150px',
        height: '40px',
        marginBottom: '5px'
    },
    textHeader: {
        fontSize: 11,
        fontFamily: 'Helvetica-Bold',
        textAlign: "center",
    },
    section: {
        padding: 10,
    },
    tableContainer: {
        width: '100%',
        alignSelf: 'center',
        border: '1 solid black', // Adicionado borda à tabela
        marginBottom: '10px',
    },
    row: {
        flexDirection: 'row',
        borderBottom: '1 solid black', // Adicionado borda inferior à linha
    },
    cell: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 12,
        padding: 1,
        width: '30%',
        paddingLeft: '5px',
    },
    cellHeader: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 11,
        padding: 3,
        width: '100%',
    },
    viewRow: {
        flexDirection: 'row',
        fontSize: 12,
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    view2: {
        fontSize: 12,
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '20px',
    },
    text1: {
        margin: '10px',
        fontSize: 13,
        marginHorizontal: '20px',
    },
    text3: {
        fontSize: 12,
    },
    text4: {
        fontSize: 12,
        paddingTop: '6px',
        marginTop: '20px',
        borderTop: '1px solid black',
    },
    text2: {
        fontSize: 12,
        paddingTop: '6px',
        borderTop: '1px solid black',
    },
    cell1: {
        width: '100%',
        fontSize: 11,
        padding: 3,
        borderLeftWidth: '1px',
        borderLeftColor: 'black',
    },
    headerCell: {
        fontWeight: 'bold',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textLine: {
        textDecoration: 'underline',
        fontSize: 12,
    },
    column1: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 12,
        marginHorizontal: '20px',
        marginVertical: '10px',
    },
    miniText: {
        width: '4%',
        fontSize: 13,
    },
    newText: {
        fontSize: 12,
        width: '50%',
        marginVertical: '10px',
    }
});

const formatDate = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = months[Number(dateParts[1]) - 1];
    const day = Number(dateParts[2]);

    return `${day} de ${month} de ${year}`;
};
const TrusteeCondicoes = ({ dados }) => {
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const calcData = JSON.parse(localStorage.getItem('calcData'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const currentZoom = window.devicePixelRatio;
    const endereco = `${emissor.rua}, ${emissor.numero} - ${emissor.bairro} - ${emissor.cidade}/${emissor.estado} CEP: ${emissor.cep}`;
    const dadosBancarios = `Banco: ${emissor.banco}/Agência: ${emissor.agency}/Conta:${emissor.conta}`;
    console.log(currentZoom);

    const linhaDoVencimento = calcData[calcData.length - 1]
    const linhaPrimeira = calcData[0]
    const dataPrimeira = linhaPrimeira[1] < 10 ? `0${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}` : `${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}`;
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    const lugarEData = `${investidor.cidade}, ${dataPrimeira}`;
    console.log(dataDoVencimento);
    const now = formValues.dataEmissao ? parseDateHyphen(formValues.dataEmissao):new Date();
    const dataEmissao = formatDateString(now);

    const avalistas = emissor.avalistas || []; // Certifique-se de que a propriedade 'avalistas' exista


    // Calcula o tamanho do componente PDFViewer com base no zoom
    return (
        <PDFViewer width={'90%'} height={'85%'}>
            <Document>
                <Page style={styles.page} size='A4'>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                        <Text style={[styles.textHeader, styles.textBold]}>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ESCRITURAÇÃO DE NOTAS COMERCIAIS </Text>
                    </View>

                    <View style={[styles.section]}>
                        <Text style={styles.text1}>Pelo presente Contrato de Prestação de Serviços de Escrituração de Valores Mobiliários,
                            doravante denominado simplesmente “Contrato”, as partes:</Text>
                        <Text style={[styles.text1, { marginVertical: '10px' }]}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                                TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.
                            </Text>
                            , sociedade
                            de responsabilidade limitada, com sede na cidade de São Paulo, Estado de São Paulo, na
                            Avenida Brigadeiro Faria lima, 3.477, 11º andar, Torre A, Bairro Itaim Bibi, CEP 04.538-
                            133, inscrita no Cadastro Nacional de Pessoa Jurídica do Ministério da Fazenda
                            (“CNPJ/MF”) sob o n.º 67.030.395/0001-46, neste ato representada por seus
                            representantes legais devidamente constituídos nos termos de seu contrato social,
                            doravante denominado de “ESCRITURADOR”; e </Text>
                        <Text style={[styles.text1, {
                            marginVertical: '10px'
                        }]}>
                            <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                                {emissor.razaoSocial}</Text> empresa com responsabilidade limitada, sem registro
                            de capital aberto na Comissão de Valores Mobiliários (“CVM”), com sede na {emissor.rua}, Nº {emissor.numero}, cidade de {emissor.cidade},
                            estado do {emissor.estado},  inscrita no Cadastro Nacional de
                            Pessoa Jurídica do Ministério da Fazenda (CNPJ/MF) sob o nº
                            {formatCnpj(emissor.cnpj)},neste
                            ato representada por seus representantes legais devidamente constituídos nos termos
                            de seu contrato social, doravante denominado de “CLIENTE”;
                        </Text>
                        <Text style={styles.text1}>
                            O <Text style={{ fontFamily: 'Helvetica-Bold' }}>ESCRITURADOR</Text> e o <Text style={{ fontFamily: 'Helvetica-Bold' }}>CLIENTE</Text> são conjuntamente denominadas de “Partes” e,
                            individualmente, como “Parte”.
                        </Text>
                        <Text style={styles.text1}>
                            As Partes acima nomeadas e qualificadas, ao final assinadas, devidamente representadas
                            por seus representantes legais, conforme disposto em seus atos constitutivos ou demais
                            documentos, têm entre si certo e ajustado o presente Contrato, nos termos das cláusulas
                            e das condições seguintes: </Text>
                        <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                            CLÁUSULA I{'\n'}
                            DO OBJETO
                        </Text>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>1.1</Text><Text style={{ marginHorizontal: 15 }}>
                                O ESCRITURADOR, na qualidade de instituição financeira depositária, prestará
                                ao CLIENTE, serviços de escrituração das notas comerciais decorrentes da {dados.emissao} emissão de notas comerciais do CLIENTE, em série única, de espécie
                                quirografária, para colocação privada (“Termo de Emissão”), para fins do disposto
                                no Artigo 48, da Lei nº 14.195/2021.
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.section]}>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}></Text><Text style={{ marginHorizontal: 15 }}>
                                (i) Das informações relativas à titularidade dos Ativos (“Investidores”);{'\n'}
                                {'\n'}
                                (ii) Dos direitos reais de fruição ou de garantia e de outros gravames
                                incidentes sobre os Ativos;{'\n'}
                                {'\n'}

                                (iii) Das movimentações dos Ativos, não limitando-se aos procedimentos
                                necessários, à aplicação dos Ativos, quando for o caso, do regime de
                                depósito centralizado;{'\n'}
                                {'\n'}

                                (iv) Do tratamento de eventos incidentes, de acordo com à cláusula II, a
                                legislação vigente e posteriores alterações;
                            </Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>1.2</Text><Text style={{ marginHorizontal: 15 }}>
                                O ESCRITURADOR, na qualidade de instituição financeira depositária, prestará
                                ao CLIENTE, serviços de escrituração das notas comerciais decorrentes da {dados.emissao} emissão de notas comerciais do CLIENTE, em série única, de espécie
                                quirografária, para colocação privada (“Termo de Emissão”), para fins do disposto
                                no Artigo 48, da Lei nº 14.195/2021.</Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>1.3</Text><Text style={{ marginHorizontal: 15 }}>
                                O presente Contrato entrará em vigor a partir da data de distribuição dos Ativos
                                e vigorará até o vencimento dos mesmos, nos termos do respectivo termo de
                                emissão, podendo, contudo, ser prorrogado, de comum acordo entre as partes
                                mediante termo aditivo.
                            </Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>1.4</Text><Text style={{ marginHorizontal: 15 }}>
                                Caso haja o resgate total dos Ativos, sendo retirados de circulação antes de seu
                                vencimento, o CLIENTE compromete-se a informar tal fato imediatamente ao
                                ESCRITURADOR, para que sejam providenciados os termos cabíveis para o
                                encerramento do presente Contrato e respectivo fechamento do Livro de
                                Registro de Ativos.
                            </Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>1.5</Text><Text style={{ marginHorizontal: 15 }}>
                                O CLIENTE declara que somente contratou o ESCRITURADOR para os serviços
                                de Escrituração de Valores Mobiliários.
                            </Text>
                        </View>
                        <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                            CLÁUSULA II{'\n'}
                            DISCRIMINAÇÃO DOS SERVIÇOS
                        </Text>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>2.1</Text><Text style={{ marginHorizontal: 15 }}>
                                O ESCRITURADOR implantará em seu sistema de escrituração, com a finalidade
                                de formar o banco de dados de investidores do CLIENTE, na totalidade dos Ativos
                                emitidos, no mínimo, as seguintes informações:</Text>
                        </View>
                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.section]}>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}></Text><Text style={{ marginHorizontal: 15 }}>
                                (i) Das informações relativas à titularidade dos Ativos (“Investidores”);{'\n'}
                                {'\n'}
                                (ii) Dos direitos reais de fruição ou de garantia e de outros gravames
                                incidentes sobre os Ativos;{'\n'}
                                {'\n'}

                                (iii) Das movimentações dos Ativos, não limitando-se aos procedimentos
                                necessários, à aplicação dos Ativos, quando for o caso, do regime de
                                depósito centralizado;{'\n'}
                                {'\n'}

                                (iv) Do  tratamento  de  eventos  incidentes,  de  acordo  com  à  cláusula  II,  a
                                legislação vigente e posteriores alterações;
                                {'\n'}
                                {'\n'}

                                (v) Investidor  pessoa  jurídica,  informando  a  razão  social  e  o  respectivo
                                cadastro nacional de pessoas jurídicas; {'\n'}
                                {'\n'}

                                (vi) Quando  for  o  caso,  o  acordo  de  Investidores,  o  usufruto,  a  alienação
                                fiduciária  em  garantia  e  quaisquer  cláusulas  ou  ônus  que  recaírem  sobre  os
                                Ativos.</Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>2.2</Text><Text style={{ marginHorizontal: 15 }}>
                                Caso a distribuição dos Ativos não seja realizada de forma pública, o  CLIENTE,
                                encaminhará  ao  ESCRITURADOR,  por  meio  eletrônico  (via  internet,  e-mail  ou
                                fac-símile)  ou  físico,  os  dados  constantes  no  item  2.1.1  acima,  bem  como  o
                                respectivo boletim de subscrição e uma via da escritura de emissão descrita na
                                cláusula 1.2.
                            </Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>2.3</Text><Text style={{ marginHorizontal: 15 }}>
                                Fica sob a responsabilidade da Central Depositária a divulgação ao
                                ESCRITURADOR  para  registro  em  Conta  de  Ativos  da  custódia  fiduciária  em
                                nome daquela entidade, dos titulares com a respectiva quantidade de Ativos de
                                emissão do CLIENTE, os quais serão registrados no sistema escritural
                                identificando  os  respectivos  Investidores  a  partir  das  informações  fornecidas
                                pelos depositários centrais.
                            </Text>
                        </View>
                        <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                            CLÁUSULA III{'\n'}
                            ATENDIMENTO AOS INVESTIDORES
                        </Text>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>3.1</Text><Text style={{ marginHorizontal: 15 }}>
                                O ESCRITURADOR realizará o atendimento aos Investidores ou seus
                                representantes legais em sua sede social ou por e-mail,
                                escrituracao@trusteedtvm.com.br, em horário comercial, para fins de
                                fornecimento  de  informações  diversas  e  solicitações  de  registro  de  processos
                                relativos aos valores mobiliários de emissão do  CLIENTE, devendo os titulares
                                dos valores mobiliários apresentarem-se munidos dos documentos de
                                identificação.</Text>
                        </View>

                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.section]}>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>3.2</Text><Text style={{ marginHorizontal: 15 }}>
                                O  ESCRITURADOR  promoverá  o  registro  dos  processos  demandados  pelos
                                Investidores no menor prazo possível e sem prejuízo da segurança necessária,
                                devendo os processos estarem amparados em documentos juridicamente
                                válidos.
                            </Text>
                        </View>
                        <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                            CLÁUSULA IV{'\n'}
                            INFORMAÇÕES DISPONÍVEIS AO CLIENTE
                        </Text>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>4.1</Text><Text style={{ marginHorizontal: 15 }}>
                                O ESCRITURADOR disponibilizará ao CLIENTE os seguintes dados dos
                                Investidores:
                            </Text>

                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}></Text><Text style={{ marginHorizontal: 15 }}>
                                (i) Informações pertinentes à identificação dos investidores, à quantidade, à
                                natureza,  à  espécie  e  à  classe  dos  Ativos  dos  respectivos  Investidores,  ou  dos
                                depositários centrais, ao histórico das movimentações realizadas nas Contas de
                                Ativos desde sua abertura, ao detalhamento dos gravames e ônus, caso existam,
                                aos eventos incidentes e aos pagamentos e recebimentos dos recursos
                                financeiros oriundos dos eventos incidentes sobre os Ativos;
                                {'\n'}
                                {'\n'}
                                (ii) Relatório  de  todos  os  Investidores  detentores  de  Ativos  emitidos  pelo
                                CLIENTE, com a identificação e qualificação, refletindo a posição total dos Ativos
                                emitidos, incluindo a abertura analítica dos Investidores com posições mantidas
                                sob a titularidade fiduciário do depositário central, quando for o caso.{'\n'}
                            </Text>
                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>4.2</Text><Text style={{ marginHorizontal: 15 }}>
                                O ESCRITURADOR colocará à disposição do CLIENTE através de meio físico e/ou
                                eletrônico os dados de posição dos Investidores, da sua identificação e endereço.
                            </Text>

                        </View>
                        <Text style={[styles.textHeader, {
                            marginVertical: '10px', marginHorizontal: '10px'
                        }]}>
                            CLÁUSULA V{'\n'}
                            DAS PESSOAS AUTORIZADAS, PESSOAS DE CONTATO E DA TRANSMISSÃO DE
                            INFORMAÇÕES
                        </Text>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>5.1</Text><Text style={{ marginHorizontal: 15 }}>
                                O  ESCRITURADOR  prestará  informações  e/ou  acatará  as  ordens  da  CLIENTE
                                assinadas, somente pelos representantes legais da CLIENTE.
                            </Text>

                        </View>
                        <View style={[styles.column1, { marginVertical: '10px' }]}>
                            <Text style={styles.miniText}>5.2</Text><Text style={{ marginHorizontal: 15 }}>
                                Todas as comunicações entre as Partes acerca deste Contrato deverão ser feitas
                                por escrito e entregues por carta registrada com Aviso de Recebimento, em mãos,
                                ou  enviadas  por  meio  de  fac-símile  (neste  caso,  sujeito  a  confirmação  do
                                recebimento da transmissão), ou por e-mail e métodos eletrônicos similares, para
                                o endereço adequado e para os números de telefone de fax estabelecidos abaixo
                                (ou para outros endereços ou números de telefone ou endereços eletrônicos que
                                cada Parte venha a informar por escrito à outra):
                            </Text>

                        </View>
                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <Text style={[styles.newText, { marginHorizontal: '25px' }]}>
                        Se ao CLIENTE:
                        {emissor.razaoSocial} {'\n'}
                        {emissor.rua}, nº {emissor.numero}, {emissor.cidade} - {emissor.estado}
                        {'\n'}At: {emissor.representante}{'\n'}
                        E-mail: {emissor.email}
                    </Text>
                    <Text style={[styles.newText, { marginHorizontal: '25px' }]}>
                        Se ao ESCRITURADOR:{'\n'}
                        Avenida Brigadeiro Faria Lima, 3477, Torre A, 11º andar
                        {'\n'}At.:
                        Estevam Borali{'\n'}
                        Telefone: (11) 2197-4452/ (11) 2197-4450{'\n'}
                        E-mail: escrituração@trusteedtvm.com.br /{'\n'} eborali@trusteedtvm.com.br
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>5.3</Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR cumprirá, sem qualquer responsabilidade, as instruções que
                            acredi&shy;tar de boa-fé, terem sido dadas pelos representantes da CLIENTE. </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA VI{'\n'}
                        DAS RESPONSABILIDADE, OBRIGAÇÕES E DECLARAÇÃO DO ESCRITURADOR
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>6.1</Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR envidará os melhores esforços e adotará, no desempenho de
                            suas atribuições e no cumprimento de seus deveres, o mesmo padrão e zelo
                            atribuído aos seus próprios ativos e passará a se responsabilizar por eventuais
                            perdas e/ou danos resultantes de dolo, fraude e/ou culpa, desde que tais perdas
                            estejam em consonância direta com os serviços objeto deste Contrato ou por
                            terceiros por ele contratados, bem como sejam devidamente comprovados os
                            prejuízos que foram suportados pelo CLIENTE. Excetuam-se da responsabilidade
                            acima referida se tais perdas e/ou danos resultarem, direta ou indiretamente, de
                            causas alheias ao controle e vontade do ESCRITURADOR, ou resultantes de
                            eventuais instruções erradas, incompletas, intempestivas e/ou de omissão de
                            prestação de instruções enviadas pelo CLIENTE, que sejam necessárias à execução
                            dos serviços objeto deste contrato, sendo a responsabilidade apurada na forma
                            prevista na legislação vigente.</Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>6.2</Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR e os seus Agentes não responderão por quaisquer eventos que
                            possam ocorrer por motivo de força maior ou caso fortuito, nos termos do artigo
                            642 da Lei n.º 10.406, de 10 de janeiro de 2002, conforme alterada.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>6.3</Text><Text style={{ marginHorizontal: 15 }}>
                            As Partes reconhecem que os serviços objeto deste Contrato estão sujeitos às leis,
                            normas, costumes, procedimentos e práticas de mercado que podem ser alterados
                            ou modificados. Na hipótese de ocorrência de situações especiais de mercado,
                            incluindo aquelas de ordem política ou econômica, que possam suspender, vedar
                            ou alterar o cumprimento das obrigações e das condições estabelecidas neste
                            Contrato, tornando inviável para o ESCRITURADOR, seja sob o ponto de vista
                            jurídico ou operacional, a prestação dos serviços ora contratados, fica estabelecido
                            que o ESCRITURADOR ficará isenta de toda e qualquer responsabilidade de
                            ressarcimento por eventuais prejuízos causados ao CLIENTE e/ou a terceiros pelo
                            não cumprimento das obrigações assumidas neste Contrato, fato que não será
                            caracterizado como descumprimento de cláusula contratual ou dispositivo legal ou
                            regulamentar. </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>6.4</Text><Text style={{ marginHorizontal: 15 }}>
                            Qualquer reclamação em razão de divergência ou erro na prestação dos serviços
                            objeto deste Contrato deverá ser comunicada pelo CLIENTE ao ESCRITURADOR
                            no prazo máximo de 30 (trinta) dias contados do processamento do serviço que
                            deu origem à divergência ou ao erro. Findo tal prazo, sem qualquer comunicação
                            de divergência ou erro na prestação dos serviços pelo CLIENTE, ao
                            ESCRITURADOR não mais será responsável por qualquer divergência ou erro
                            existente na prestação dos serviços objeto deste Contrato.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>6.5</Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR declara que:
                        </Text>

                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={{ marginHorizontal: 15 }}>
                            (i) toma todas as medidas necessárias para a identificação da titularidade dos
                            valores mobiliários, para a garantia de sua integridade e para a certeza sobre a
                            origem das instruções recebidas;{'\n'}
                            {'\n'}
                            (ii) promove os atos necessários ao registro de gravames ou de direitos sobre
                            valores mobiliários custodiados, tomando todas as medidas necessárias para a
                            sua adequada formalização;{'\n'}
                            {'\n'}
                            (iii) assegura, de forma permanente, a qualidade de seus processos e sistemas
                            informatizados, mensurando e mantendo registro dos acessos, erros, incidentes
                            e interrupções em suas operações;
                            {'\n'}
                            {'\n'}
                            (iv) garante a segurança física de seus equipamentos e instalações, com o
                            estabelecimento de normas de segurança de dados e informações que os
                            protejam de acesso de pessoal não autorizado;
                            {'\n'}
                            {'\n'}
                            (v) dispõe de recursos humanos suficientes e tecnicamente capazes de
                            realizar os processos e operar os sistemas envolvidos na prestação dos serviços
                            de escrituração; {'\n'}
                            {'\n'}

                            (vi) mantém atualizados os manuais operacionais, a descrição geral dos
                            sistemas a serem adotados na prestação dos serviços, o fluxograma de rotinas,
                            a documentação de programas, os controles de qualidade e os regulamentos
                            de segurança física e lógica;
                            {'\n'}
                            {'\n'}
                            (vii) implementou e mantém atualizado plano de contingência que assegure
                            a continuidade de negócios e a prestação dos serviços.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA VII{'\n'}
                        DAS OBRIGAÇÕES E RESPONSABILIDADES DA CONTRANTE
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>7.1</Text><Text style={{ marginHorizontal: 15 }}>
                            O CLIENTE é a única responsável pela emissão dos Ativos e, portanto, única
                            titular dos direitos e obrigações decorrentes de tal condição, devendo estar
                            enquadrada e em conformidade com toda a legislação e regulamentação
                            pertinentes.
                        </Text>

                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>7.2</Text><Text style={{ marginHorizontal: 15 }}>
                            O CLIENTE obriga-se a observar as disposições e obrigações deste Contrato, da
                            regulamentação e legislação aplicável.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>7.3</Text><Text style={{ marginHorizontal: 15 }}>
                            O CLIENTE terá prazo de 30 (trinta) dias, contados do recebimento de cada
                            relatório, para indicar eventual irregularidade de escrituração ou de execução das
                            suas instruções dadas ao ESCRITURADOR.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>7.4</Text><Text style={{ marginHorizontal: 15 }}>
                            O CLIENTE obriga-se a apresentar ao ESCRITURADOR, até a data da assinatura
                            deste Contrato, cópia autenticada do Estatuto Social, ata de Eleição de Diretoria,
                            Procuração, se for o caso, entre outros documentos que se fizerem necessários
                            para a comprovação da representação da CLIENTE.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA VIII{'\n'}
                        DA REMUNERAÇÃO DO ESCRITURADOR
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>8.1</Text><Text style={{ marginHorizontal: 15 }}>
                            Os valores devidos pela prestação de serviços de liquidante deverão ser pagos
                            pelo CLIENTE na íntegra, livres dos seguintes tributos, imposto sobre serviços de
                            qualquer natureza – ISS, Programas de Integração Social – PIS e Contribuição
                            para Financiamento da Seguridade Social – COFINS, excetuando-se o Imposto de
                            Renda.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>8.2</Text><Text style={{ marginHorizontal: 15 }}>
                            Quaisquer faturas enviadas ao CLIENTE, e não pagas até seu vencimento, bem
                            como quaisquer outros valores devidos e não pagos ao ESCRITURADOR,
                            incluindo em razão de indenizações, serão acrescidas de juros de mora de 1%
                            (um por cento) ao mês, calculados pro rata die desde a data do vencimento até
                            a data do efetivo pagamento, mas multa moratória de 2% (dois por cento) sobre
                            o valor devido e não pago.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA IX{'\n'}
                        DO PRAZO DE VIGÊNCIA E DA RESCISÃO
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>9.1</Text><Text style={{ marginHorizontal: 15 }}>
                            O presente Contrato entrará em vigor a partir da data de sua assinatura e vigorará
                            por prazo indeterminado ou conforme prazo definido na emissão dos Ativos,
                            conforme cláusula 1.3, podendo ser resilido a qualquer momento, por qualquer
                            das Partes, sem direito a compensações ou indenizações, mediante notificação da
                            Parte interessada para a outra Parte, por escrito, com no mínimo 60 (sessenta) dias
                            de antecedência, contados do recebimento do comunicado pela outra Parte.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>9.2</Text><Text style={{ marginHorizontal: 15 }}>
                            Além das disposições previstas em lei, este Contrato poderá ser rescindido de
                            imediato, mediante aviso à outra Parte, nas seguintes hipóteses: </Text>
                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={{ marginHorizontal: 15 }}>
                            (i) Na superveniência de qualquer normativo ou instrução das autoridades
                            competentes, notadamente do BACEN e CVM, que impeça a contratação objeto
                            deste contrato;
                            {'\n'}
                            {'\n'}
                            (ii) Se qualquer das Partes falir, requerer recuperação judicial ou iniciar
                            procedimentos de recuperação extrajudicial, tiver sua falência, intervenção ou
                            liquidação requerida; {'\n'}
                            {'\n'}
                            (iii) Se qualquer das partes tiver cassada sua autorização para execução dos
                            serviços ora contratados;
                            {'\n'}
                            {'\n'}
                            (iv) Se não houver pagamento da remuneração devida ao ESCRITURADOR;{'\n'}
                            {'\n'}
                            (v) Se qualquer das Partes suspender suas atividades por qualquer período
                            igual ou superior a 30 (trinta) dias.{'\n'}
                            {'\n'}</Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>9.3</Text><Text style={{ marginHorizontal: 15 }}>
                            Em caso de descontinuidade na prestação de serviços de escrituração, ao CLIENTE,
                            deverá substituir a ESCRITURADOR em até 15 (quinze) dias úteis, nos termos da
                            Resolução nº 33/2021 da Comissão de Valores Mobiliários.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA X{'\n'}
                        DO SIGILO
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>10.1</Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR e o CLIENTE obrigam-se a não revelar, não utilizar ou, de
                            qualquer forma, não difundir quaisquer informações ou documentos que venham
                            a ter conhecimento em virtude da prestação dos serviços objeto deste Contrato,
                            sem prévia autorização, por escrito, da Parte ou do Interveniente Anuente a quem
                            tais informações ou documentos se referirem. </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>10.2 </Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR guardará sigilo das informações ou documentos relacionados
                            ao CLIENTE, relativos aos serviços objeto deste instrumento, divulgando-os tão
                            somente: </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}></Text><Text style={{ marginHorizontal: 15 }}>
                            (i) ao órgão regulador ou autoridade governamental competente, quando
                            requisitados ou para cumprimento de exigências legais e administrativas,
                            na forma e prazo indicados na legislação ou regulamentação em vigor;{'\n'}
                            {'\n'}
                            (ii) em virtude de ordem judicial; ou{'\n'}
                            {'\n'}

                            (iii) quando expressamente autorizado pela Parte ou pelo Interveniente
                            Anuente ou na medida necessária para a prestação dos serviços ora contratados.{'\n'}
                            {'\n'}
                        </Text>
                    </View>

                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>10.3 </Text><Text style={{ marginHorizontal: 15 }}>
                            Estão excluídas da obrigação de sigilo prevista nesta Cláusula as informações ou
                            documentos de domínio público, bem como os documentos que devem
                            periodicamente ser enviados aos órgãos reguladores ou autoridade
                            governamental competente, nos termos da legislação em vigor, caso aplicável.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA XI{'\n'}
                        DAS INFRAÇÕES E PENALIDADES
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>11.1 </Text><Text style={{ marginHorizontal: 15 }}>
                            O inadimplemento pela CLIENTE de quaisquer obrigações de pagamento previstas
                            neste contrato caracterizará, de pleno direito, independentemente de qualquer
                            aviso ou notificação, a mora da Parte inadimplente, sujeitando-a aos pagamentos
                            dos encargos, na cláusula VIII acima.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>11.2 </Text><Text style={{ marginHorizontal: 15 }}>
                            O descumprimento de qualquer condição prevista neste Contrato por qualquer das
                            Partes, e desde que devidamente comprovado, obrigará a Parte infratora a
                            responder por eventuais perdas e/ou danos resultantes de dolo, fraude e/ou culpa,
                            responsabilizando-se ademais pelas multas, atualizações monetárias e juros dai
                            decorrentes, apurados na forma prevista na legislação em vigor.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>11.3 </Text><Text style={{ marginHorizontal: 15 }}>
                            Não serão penalizáveis quaisquer atrasos que decorram de falhas de sistema e/ou
                            de comunicação entre as Partes, as quais, não obstante, deverão diligenciar para a
                            imediata correção de tais falhas.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA XII{'\n'}
                        DA UTILIZAÇÃO DE NOMES, MARCAS E LOGOMARCAS
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>12.1 </Text><Text style={{ marginHorizontal: 15 }}>
                            A utilização dos nomes, das marcas e logomarcas de uma Parte, assim como
                            qualquer publicidade acerca do serviço prestado, fica condicionada à prévia
                            autorização por escrito da outra Parte proprietário do nome, marca e logomarca.
                        </Text>
                    </View>
                    <Text style={[styles.textHeader, { marginVertical: '10px' }]}>
                        CLÁUSULA XIII{'\n'}
                        DISPOSIÇÕES FINAIS
                    </Text>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.1 </Text><Text style={{ marginHorizontal: 15 }}>
                            Este Contrato somente poderá ser alterado por aditamento assinado pelas Partes.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.2 </Text><Text style={{ marginHorizontal: 15 }}>
                            As Partes reconhecem a unicidade e indivisibilidade das disposições deste
                            Contrato, que deverá ser sempre interpretado de forma harmônica e sistemática,
                            tendo como parâmetro a finalidade dos serviços a serem prestados pelo
                            ESCRITURADOR
                        </Text>
                    </View>


                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.3 </Text><Text style={{ marginHorizontal: 15 }}>
                            A tolerância das Partes com relação à ação, omissão ou não cumprimento de
                            qualquer obrigação aqui avençada será considerada mera liberalidade, não
                            implicando em novação dos termos deste Contrato nem em renúncia ao direito de
                            exigir o cumprimento integral deste Contrato, a qualquer tempo.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.4 </Text><Text style={{ marginHorizontal: 15 }}>
                            Este Contrato obriga as Partes e seus respectivos sucessores, a qualquer título.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.5</Text><Text style={{ marginHorizontal: 15 }}>
                            O ESCRITURADOR poderá, a qualquer tempo, transferir, onerar ou ceder, no
                            todo ou em parte, seus direitos e obrigações decorrentes do presente Contrato
                            para qualquer instituição pertencente ao mesmo grupo econômico, desde que a
                            mencionada instituição esteja habilitada a prestar os serviços objeto deste
                            Contrato.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.6</Text><Text style={{ marginHorizontal: 15 }}>
                            Este Contrato constitui acordo integral entre as Partes, não prevalecendo quaisquer
                            entendimentos ou acordos anteriores, bem como todas as comunicações feitas
                            entre as partes. Nenhuma alteração, renúncia ou quitação será considerada válida,
                            a menos que efetuada por escrito e assinada pelas Partes e pelo Interveniente
                            Anuente.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.7</Text><Text style={{ marginHorizontal: 15 }}>
                            A nulidade ou invalidade de qualquer das disposições do Contrato não implica na
                            nulidade ou invalidade das demais. As disposições nulas ou inválidas deverão ser
                            reescritas pelas Partes mediante aditamento ao presente Contrato, de modo a
                            refletir a intenção inicial das Partes em conformidade com a legislação vigente.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.8</Text><Text style={{ marginHorizontal: 15 }}>
                            As Partes declaram-se cientes de que poderão: (i) manter relacionamento bancário,
                            comercial e de prestação de serviços com quaisquer outras instituições do
                            mercado, inclusive atuando como prestador de serviços para fundo(s) de
                            investimento administrados por qualquer outra instituição, inexistindo
                            exclusividade na prestação dos serviços objeto do presente Contrato e/ou (ii)
                            comprar, deter ou negociar quaisquer títulos, valores mobiliários e ativos
                            financeiros disponíveis no mercado, tais quais os Ativos, para si ou para terceiros.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.9</Text><Text style={{ marginHorizontal: 15 }}>
                            As Partes reciprocamente autorizam a gravação de todas as conversas telefônicas
                            mantidas entre as Partes nos termos deste Contrato, podendo inclusive ser as
                            mesmas utilizadas como meio de prova para todo e qualquer fim de direito.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={styles.miniText}>13.10</Text><Text style={{ marginHorizontal: 15 }}>
                            Não existe vínculo empregatício entre os funcionários das Partes, nem se
                            estabelecerá entre eles qualquer forma de associação, solidariedade ou vínculo
                            societário, competindo, portanto, a cada um deles, particularmente e com
                            exclusividade, o cumprimento das suas respectivas obrigações trabalhistas, sociais,
                            previdenciárias e fiscais, na forma da legislação em vigor.
                        </Text>
                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end', marginTop: '10px' }]} />
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/gFLX54b/trustee.jpg" style={[styles.image, { width: '100%', marginBottom: '25px', height: '35px' }]} />
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={[{ marginHorizontal: '5px' }]}>
                        As Partes elegem o Foro da Comarca de {emissor.cidade}, Estado de {estadoPorExtenso(emissor.estado)}, como o único
                            competente para dirimir as questões e litígios decorrentes deste Contrato de Prestação
                            de Serviços, renunciando expressamente a qualquer outro, por mais privilegiado que seja
                            ou venha a ser, bem como declaram e concordam que o presente instrumento, incluindo
                            todas as páginas de assinatura e eventuais anexos, todas formadas por meio digital, com
                            o qual expressamente declaram concordar, que representam a integralidade dos termos
                            entre elas acordados, substituindo quaisquer outros instrumentos anteriores
                            formalizados por qualquer outro meio, verbal ou escrito, físico ou digital, nos termos dos
                            artigos 107, 219 e 220 do Código Civil.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={[{ marginHorizontal: '15px' }]}>
                            E, por estarem justas e contratadas as Partes assinam o presente Contrato de forma
                            digital, na presença das testemunhas abaixo.
                        </Text>
                    </View>
                    <View style={[styles.column1, { marginVertical: '10px' }]}>
                        <Text style={[{ padding: '10px', textAlign: 'center', paddingTop: '10px', fontSize: 13 }]}>
                            {emissor.cidade}, {dataEmissao}
                        </Text>
                    </View>
                    <View style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Text style={[{ marginTop: 20, marginHorizontal: '20px', marginBottom: '20px', fontFamily: 'Helvetica-Bold', textAlign: 'center', borderTopWidth: '1px', borderTopColor: '#000', width: '40%', fontSize: 13, paddingTop: '10px' }]}>
                            {emissor.razaoSocial}{'\n'}
                        </Text>
                    </View>
                    <View style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Text style={[{ marginTop: 20, marginHorizontal: '20px', marginBottom: '20px', fontFamily: 'Helvetica-Bold', textAlign: 'center', borderTopWidth: '1px', borderTopColor: '#000', width: '40%', fontSize: 13, paddingTop: '10px' }]}>
                            TRUSTEE DTVM LTDA{'\n'}
                        </Text>
                    </View>
                    <Text style={[styles.textLine, { marginHorizontal: '15px' }]}>
                        TESTEMUNHAS:
                    </Text>
                    <View style={{ width: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                        <View style={[styles.view2, { marginBottom: 20, width: '50%' }]} >
                            <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px' }]}>Nome: Juliana Mayumi Nagai</Text>
                            <Text style={{ marginHorizontal: '20px', marginTop: '10px' }}>CPF: 443.265.778-27 </Text>
                        </View>
                        <View style={[styles.view2, { marginBottom: 20, width: '50%' }]} >
                            <Text style={[styles.text2, { marginTop: 20, marginHorizontal: '20px' }]}>Nome: Lucas Ferreira Pelá </Text>
                            <Text style={{ marginHorizontal: '20px', marginTop: '10px' }}>CPF: 475.486.868-46</Text>
                        </View>
                    </View>
                    <Text style={{ fontSize: 10, marginHorizontal: '25px', opacity: 0.8 }}>
                        “O PRESENTE PRESTADOR DE SERVIÇOS COMPROMETEU-SE A ATENDER AOS PADRÕES MÍNIMOS
                        EXIGIDOS PELO CÓDIGO ANBIMA DE REGULAÇÃO E MELHORES PRÁTICAS DOS SERVIÇOS QUALIFICADOS
                        AO MERCADO DE CAPITAIS, NÃO CABENDO À ANBIMA QUALQUER RESPONSABILIDADE PELOS SERVIÇOS
                        PRESTADOS, NEM POR QUAISQUER ATOS OU FATOS DELES DECORRENTES OU A ELES PERTINENTES.”
                    </Text>
                    <View style={{ marginVertical: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                        <Image src="https://i.ibb.co/ZmDx3fQ/Imagem1.png" style={[styles.image, { width: '20%', height: '45px', alignSelf: 'flex-end' }]} />
                    </View>
                    <View style={[styles.footer, { marginVertical: '10px' }]}>
                        <Image src="https://i.ibb.co/W2zVMs7/Imagem1.jpg" style={[styles.image, { width: '100%', height: '62px', alignSelf: 'flex-end' }]} />
                    </View>
                </Page>
            </Document>
        </PDFViewer >
    )
}

export default TrusteeCondicoes;
